.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300italic,300,400italic,700,700italic);

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6672e8;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #2083fe;
  --bs-secondary: #5a6c90;
  --bs-success: #34bd91;
  --bs-info: #18b7ff;
  --bs-warning: #fdc724;
  --bs-danger: #bd4242;
  --bs-light: #f6f9fc;
  --bs-dark: #1e2e50;
  --bs-purple: #6672e8;
  --bs-sky: #e9f4ff;
  --bs-pistachio: #ddf5f4;
  --bs-darkblue: #1f2c73;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: SpartanMB, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: #dee2e6;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {

  .h1,
  h1 {
    font-size: 2.5rem;
  }
}

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {

  .h2,
  h2 {
    font-size: 2rem;
  }
}

.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {

  .h3,
  h3 {
    font-size: 1.75rem;
  }
}

.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  .h4,
  h4 {
    font-size: 1.5rem;
  }
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

.small,
small {
  font-size: 0.875em;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2083fe;
  text-decoration: none;
}

a:hover {
  color: #015ed1;
  text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -ms-overflow-style: scrollbar;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #7184ad;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.7rem + 5.4vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5.75rem;
  }
}

.display-2 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.445rem + 2.34vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.2rem;
  }
}

.display-5 {
  font-size: calc(1.41rem + 1.92vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.85rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1500px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: #eef4f9;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #e9ecef;
}

.table> :not(caption)>*>* {
  padding: 1rem 1rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg),
      var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #c1dcff;
  --bs-table-striped-bg: #b7d1f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #aec6e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b3ccec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #aec6e6;
}

.table-secondary {
  --bs-table-bg: #d1d6e0;
  --bs-table-striped-bg: #c7cbd5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcc1ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c6cf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcc1ca;
}

.table-success {
  --bs-table-bg: #c6ede0;
  --bs-table-striped-bg: #bce1d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b2d5ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b7dbcf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b2d5ca;
}

.table-info {
  --bs-table-bg: #beebff;
  --bs-table-striped-bg: #b5dff2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #abd4e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b0d9ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #abd4e6;
}

.table-warning {
  --bs-table-bg: #feefc2;
  --bs-table-striped-bg: #f1e3b8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5d7af;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebddb3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5d7af;
}

.table-danger {
  --bs-table-bg: #edcaca;
  --bs-table-striped-bg: #e1c0c0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d5b6b6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dbbbbb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d5b6b6;
}

.table-light {
  --bs-table-bg: #f6f9fc;
  --bs-table-striped-bg: #eaedef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dde0e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e6e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dde0e3;
}

.table-dark {
  --bs-table-bg: #1e2e50;
  --bs-table-striped-bg: #293859;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #354362;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2f3e5d;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #354362;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1499.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.85rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #7184ad;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #2083fe;
  outline: 0;
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0.35);
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
  opacity: 1;
}

.form-control::placeholder {
  color: rgba(0, 0, 0, 0.35);
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  border-radius: 0.2rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select:focus {
  border-color: #2083fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(32, 131, 254, 0.25);
}

.form-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.85rem;
}

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #dee2e6;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(95%);
}

.form-check-input:focus {
  border-color: #2083fe;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #2083fe;
  border-color: #2083fe;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #2083fe;
  border-color: #2083fe;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.5em;
}

.form-switch .form-check-input {
  width: 3em;
  margin-left: -3.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 3em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-file {
  --bs-form-file-height: calc(1.5em + 0.75rem + 2px);
  position: relative;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus-within~.form-file-label {
  border-color: #2083fe;
  box-shadow: none;
}

.form-file-input:disabled~.form-file-label .form-file-text,
.form-file-input[disabled]~.form-file-label .form-file-text {
  background-color: #e9ecef;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #dee2e6;
  border-radius: 0.25rem;
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-file-sm {
  --bs-form-file-height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.85rem;
}

.form-file-sm .form-file-button,
.form-file-sm .form-file-text {
  padding: 0.25rem 0.5rem;
}

.form-file-lg {
  --bs-form-file-height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem;
}

.form-file-lg .form-file-button,
.form-file-lg .form-file-text {
  padding: 0.5rem 1rem;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2083fe;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #d2e6ff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2083fe;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #d2e6ff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: #2083fe;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #d2e6ff;
}

.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.form-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-file,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-file .form-file-input:focus~.form-file-label,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group>.form-file>.form-file-input:focus {
  z-index: 4;
}

.input-group>.form-file:not(:last-child)>.form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-file:not(:first-child)>.form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1f2c73;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.input-group-lg>.form-control {
  min-height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control {
  min-height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  border-radius: 0.2rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 1.75rem;
}

.input-group>.dropdown-toggle:nth-last-child(n + 3),
.input-group> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #34bd91;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.85rem;
  color: #fff;
  background-color: rgba(52, 189, 145, 0.9);
  border-radius: 0.25rem;
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #34bd91;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334bd91' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #34bd91;
  box-shadow: 0 0 0 0.25rem rgba(52, 189, 145, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #34bd91;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334bd91' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #34bd91;
  box-shadow: 0 0 0 0.25rem rgba(52, 189, 145, 0.25);
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #34bd91;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #34bd91;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 189, 145, 0.25);
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #34bd91;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.form-file-input.is-valid~.form-file-label,
.was-validated .form-file-input:valid~.form-file-label {
  border-color: #34bd91;
}

.form-file-input.is-valid:focus~.form-file-label,
.was-validated .form-file-input:valid:focus~.form-file-label {
  border-color: #34bd91;
  box-shadow: 0 0 0 0.25rem rgba(52, 189, 145, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #bd4242;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.85rem;
  color: #fff;
  background-color: rgba(189, 66, 66, 0.9);
  border-radius: 0.25rem;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #bd4242;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23bd4242' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23bd4242' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #bd4242;
  box-shadow: 0 0 0 0.25rem rgba(189, 66, 66, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #bd4242;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23bd4242' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23bd4242' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #bd4242;
  box-shadow: 0 0 0 0.25rem rgba(189, 66, 66, 0.25);
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #bd4242;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #bd4242;
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 66, 66, 0.25);
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #bd4242;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.form-file-input.is-invalid~.form-file-label,
.was-validated .form-file-input:invalid~.form-file-label {
  border-color: #bd4242;
}

.form-file-input.is-invalid:focus~.form-file-label,
.was-validated .form-file-input:invalid:focus~.form-file-label {
  border-color: #bd4242;
  box-shadow: 0 0 0 0.25rem rgba(189, 66, 66, 0.25);
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4rem 1.1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(32, 131, 254, 0.25);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #2083fe;
  border-color: #2083fe;
}

.btn-primary:hover {
  color: #fff;
  background-color: #016ff7;
  border-color: #0169ea;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #016ff7;
  border-color: #0169ea;
  box-shadow: 0 0 0 0.25rem rgba(65, 150, 254, 0.5);
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0169ea;
  border-color: #0163dd;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(65, 150, 254, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #2083fe;
  border-color: #2083fe;
}

.btn-secondary {
  color: #fff;
  background-color: #5a6c90;
  border-color: #5a6c90;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #4b5a78;
  border-color: #465471;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #4b5a78;
  border-color: #465471;
  box-shadow: 0 0 0 0.25rem rgba(115, 130, 161, 0.5);
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #465471;
  border-color: #414f69;
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(115, 130, 161, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #5a6c90;
  border-color: #5a6c90;
}

.btn-success {
  color: #fff;
  background-color: #34bd91;
  border-color: #34bd91;
}

.btn-success:hover {
  color: #fff;
  background-color: #2c9f7a;
  border-color: #299572;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #2c9f7a;
  border-color: #299572;
  box-shadow: 0 0 0 0.25rem rgba(82, 199, 162, 0.5);
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #299572;
  border-color: #268b6b;
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 199, 162, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #34bd91;
  border-color: #34bd91;
}

.btn-info {
  color: #fff;
  background-color: #18b7ff;
  border-color: #18b7ff;
}

.btn-info:hover {
  color: #fff;
  background-color: #00a6f1;
  border-color: #009de4;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #00a6f1;
  border-color: #009de4;
  box-shadow: 0 0 0 0.25rem rgba(59, 194, 255, 0.5);
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #009de4;
  border-color: #0094d7;
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(59, 194, 255, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #18b7ff;
  border-color: #18b7ff;
}

.btn-warning {
  color: #000;
  background-color: #fdc724;
  border-color: #fdc724;
}

.btn-warning:hover {
  color: #000;
  background-color: #fdd14a;
  border-color: #fdcd3d;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #fdd14a;
  border-color: #fdcd3d;
  box-shadow: 0 0 0 0.25rem rgba(215, 169, 31, 0.5);
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fdd457;
  border-color: #fdcd3d;
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(215, 169, 31, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #fdc724;
  border-color: #fdc724;
}

.btn-danger {
  color: #fff;
  background-color: #bd4242;
  border-color: #bd4242;
}

.btn-danger:hover {
  color: #fff;
  background-color: #a13838;
  border-color: #973535;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #a13838;
  border-color: #973535;
  box-shadow: 0 0 0 0.25rem rgba(199, 94, 94, 0.5);
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #973535;
  border-color: #8e3232;
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(199, 94, 94, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #bd4242;
  border-color: #bd4242;
}

.btn-light {
  color: #000;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-light:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 0.25rem rgba(209, 212, 214, 0.5);
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light:active,
.show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(209, 212, 214, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-dark {
  color: #fff;
  background-color: #1e2e50;
  border-color: #1e2e50;
}

.btn-dark:hover {
  color: #fff;
  background-color: #141e34;
  border-color: #10192b;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #141e34;
  border-color: #10192b;
  box-shadow: 0 0 0 0.25rem rgba(64, 77, 106, 0.5);
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark:active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #10192b;
  border-color: #0d1322;
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(64, 77, 106, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #1e2e50;
  border-color: #1e2e50;
}

.btn-purple {
  color: #fff;
  background-color: #6672e8;
  border-color: #6672e8;
}

.btn-purple:hover {
  color: #fff;
  background-color: #4553e3;
  border-color: #3a49e1;
}

.btn-check:focus+.btn-purple,
.btn-purple:focus {
  color: #fff;
  background-color: #4553e3;
  border-color: #3a49e1;
  box-shadow: 0 0 0 0.25rem rgba(125, 135, 235, 0.5);
}

.btn-check:active+.btn-purple,
.btn-check:checked+.btn-purple,
.btn-purple.active,
.btn-purple:active,
.show>.btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #3a49e1;
  border-color: #2f3fe0;
}

.btn-check:active+.btn-purple:focus,
.btn-check:checked+.btn-purple:focus,
.btn-purple.active:focus,
.btn-purple:active:focus,
.show>.btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(125, 135, 235, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #6672e8;
  border-color: #6672e8;
}

.btn-sky {
  color: #000;
  background-color: #e9f4ff;
  border-color: #e9f4ff;
}

.btn-sky:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus+.btn-sky,
.btn-sky:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 0.25rem rgba(198, 207, 217, 0.5);
}

.btn-check:active+.btn-sky,
.btn-check:checked+.btn-sky,
.btn-sky.active,
.btn-sky:active,
.show>.btn-sky.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:active+.btn-sky:focus,
.btn-check:checked+.btn-sky:focus,
.btn-sky.active:focus,
.btn-sky:active:focus,
.show>.btn-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(198, 207, 217, 0.5);
}

.btn-sky.disabled,
.btn-sky:disabled {
  color: #000;
  background-color: #e9f4ff;
  border-color: #e9f4ff;
}

.btn-pistachio {
  color: #000;
  background-color: #ddf5f4;
  border-color: #ddf5f4;
}

.btn-pistachio:hover {
  color: #000;
  background-color: #fbfefe;
  border-color: #f1fbfa;
}

.btn-check:focus+.btn-pistachio,
.btn-pistachio:focus {
  color: #000;
  background-color: #fbfefe;
  border-color: #f1fbfa;
  box-shadow: 0 0 0 0.25rem rgba(188, 208, 207, 0.5);
}

.btn-check:active+.btn-pistachio,
.btn-check:checked+.btn-pistachio,
.btn-pistachio.active,
.btn-pistachio:active,
.show>.btn-pistachio.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #f1fbfa;
}

.btn-check:active+.btn-pistachio:focus,
.btn-check:checked+.btn-pistachio:focus,
.btn-pistachio.active:focus,
.btn-pistachio:active:focus,
.show>.btn-pistachio.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(188, 208, 207, 0.5);
}

.btn-pistachio.disabled,
.btn-pistachio:disabled {
  color: #000;
  background-color: #ddf5f4;
  border-color: #ddf5f4;
}

.btn-darkblue {
  color: #fff;
  background-color: #1f2c73;
  border-color: #1f2c73;
}

.btn-darkblue:hover {
  color: #fff;
  background-color: #172055;
  border-color: #141d4b;
}

.btn-check:focus+.btn-darkblue,
.btn-darkblue:focus {
  color: #fff;
  background-color: #172055;
  border-color: #141d4b;
  box-shadow: 0 0 0 0.25rem rgba(65, 76, 136, 0.5);
}

.btn-check:active+.btn-darkblue,
.btn-check:checked+.btn-darkblue,
.btn-darkblue.active,
.btn-darkblue:active,
.show>.btn-darkblue.dropdown-toggle {
  color: #fff;
  background-color: #141d4b;
  border-color: #111941;
}

.btn-check:active+.btn-darkblue:focus,
.btn-check:checked+.btn-darkblue:focus,
.btn-darkblue.active:focus,
.btn-darkblue:active:focus,
.show>.btn-darkblue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(65, 76, 136, 0.5);
}

.btn-darkblue.disabled,
.btn-darkblue:disabled {
  color: #fff;
  background-color: #1f2c73;
  border-color: #1f2c73;
}

.btn-outline-primary {
  color: #2083fe;
  border-color: #2083fe;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2083fe;
  border-color: #2083fe;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(32, 131, 254, 0.5);
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #2083fe;
  border-color: #2083fe;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(32, 131, 254, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #2083fe;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #5a6c90;
  border-color: #5a6c90;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #5a6c90;
  border-color: #5a6c90;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(90, 108, 144, 0.5);
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #5a6c90;
  border-color: #5a6c90;
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(90, 108, 144, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #5a6c90;
  background-color: transparent;
}

.btn-outline-success {
  color: #34bd91;
  border-color: #34bd91;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #34bd91;
  border-color: #34bd91;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 189, 145, 0.5);
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #34bd91;
  border-color: #34bd91;
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 189, 145, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #34bd91;
  background-color: transparent;
}

.btn-outline-info {
  color: #18b7ff;
  border-color: #18b7ff;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #18b7ff;
  border-color: #18b7ff;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(24, 183, 255, 0.5);
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #fff;
  background-color: #18b7ff;
  border-color: #18b7ff;
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(24, 183, 255, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #18b7ff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fdc724;
  border-color: #fdc724;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #fdc724;
  border-color: #fdc724;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 199, 36, 0.5);
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #fdc724;
  border-color: #fdc724;
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 199, 36, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fdc724;
  background-color: transparent;
}

.btn-outline-danger {
  color: #bd4242;
  border-color: #bd4242;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #bd4242;
  border-color: #bd4242;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 66, 66, 0.5);
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #bd4242;
  border-color: #bd4242;
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 66, 66, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #bd4242;
  background-color: transparent;
}

.btn-outline-light {
  color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 249, 252, 0.5);
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 249, 252, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f6f9fc;
  background-color: transparent;
}

.btn-outline-dark {
  color: #1e2e50;
  border-color: #1e2e50;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1e2e50;
  border-color: #1e2e50;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(30, 46, 80, 0.5);
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #1e2e50;
  border-color: #1e2e50;
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(30, 46, 80, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #1e2e50;
  background-color: transparent;
}

.btn-outline-purple {
  color: #6672e8;
  border-color: #6672e8;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #6672e8;
  border-color: #6672e8;
}

.btn-check:focus+.btn-outline-purple,
.btn-outline-purple:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 114, 232, 0.5);
}

.btn-check:active+.btn-outline-purple,
.btn-check:checked+.btn-outline-purple,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show,
.btn-outline-purple:active {
  color: #fff;
  background-color: #6672e8;
  border-color: #6672e8;
}

.btn-check:active+.btn-outline-purple:focus,
.btn-check:checked+.btn-outline-purple:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus,
.btn-outline-purple:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 114, 232, 0.5);
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #6672e8;
  background-color: transparent;
}

.btn-outline-sky {
  color: #e9f4ff;
  border-color: #e9f4ff;
}

.btn-outline-sky:hover {
  color: #000;
  background-color: #e9f4ff;
  border-color: #e9f4ff;
}

.btn-check:focus+.btn-outline-sky,
.btn-outline-sky:focus {
  box-shadow: 0 0 0 0.25rem rgba(233, 244, 255, 0.5);
}

.btn-check:active+.btn-outline-sky,
.btn-check:checked+.btn-outline-sky,
.btn-outline-sky.active,
.btn-outline-sky.dropdown-toggle.show,
.btn-outline-sky:active {
  color: #000;
  background-color: #e9f4ff;
  border-color: #e9f4ff;
}

.btn-check:active+.btn-outline-sky:focus,
.btn-check:checked+.btn-outline-sky:focus,
.btn-outline-sky.active:focus,
.btn-outline-sky.dropdown-toggle.show:focus,
.btn-outline-sky:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(233, 244, 255, 0.5);
}

.btn-outline-sky.disabled,
.btn-outline-sky:disabled {
  color: #e9f4ff;
  background-color: transparent;
}

.btn-outline-pistachio {
  color: #ddf5f4;
  border-color: #ddf5f4;
}

.btn-outline-pistachio:hover {
  color: #000;
  background-color: #ddf5f4;
  border-color: #ddf5f4;
}

.btn-check:focus+.btn-outline-pistachio,
.btn-outline-pistachio:focus {
  box-shadow: 0 0 0 0.25rem rgba(221, 245, 244, 0.5);
}

.btn-check:active+.btn-outline-pistachio,
.btn-check:checked+.btn-outline-pistachio,
.btn-outline-pistachio.active,
.btn-outline-pistachio.dropdown-toggle.show,
.btn-outline-pistachio:active {
  color: #000;
  background-color: #ddf5f4;
  border-color: #ddf5f4;
}

.btn-check:active+.btn-outline-pistachio:focus,
.btn-check:checked+.btn-outline-pistachio:focus,
.btn-outline-pistachio.active:focus,
.btn-outline-pistachio.dropdown-toggle.show:focus,
.btn-outline-pistachio:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(221, 245, 244, 0.5);
}

.btn-outline-pistachio.disabled,
.btn-outline-pistachio:disabled {
  color: #ddf5f4;
  background-color: transparent;
}

.btn-outline-darkblue {
  color: #1f2c73;
  border-color: #1f2c73;
}

.btn-outline-darkblue:hover {
  color: #fff;
  background-color: #1f2c73;
  border-color: #1f2c73;
}

.btn-check:focus+.btn-outline-darkblue,
.btn-outline-darkblue:focus {
  box-shadow: 0 0 0 0.25rem rgba(31, 44, 115, 0.5);
}

.btn-check:active+.btn-outline-darkblue,
.btn-check:checked+.btn-outline-darkblue,
.btn-outline-darkblue.active,
.btn-outline-darkblue.dropdown-toggle.show,
.btn-outline-darkblue:active {
  color: #fff;
  background-color: #1f2c73;
  border-color: #1f2c73;
}

.btn-check:active+.btn-outline-darkblue:focus,
.btn-check:checked+.btn-outline-darkblue:focus,
.btn-outline-darkblue.active:focus,
.btn-outline-darkblue.dropdown-toggle.show:focus,
.btn-outline-darkblue:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(31, 44, 115, 0.5);
}

.btn-outline-darkblue.disabled,
.btn-outline-darkblue:disabled {
  color: #1f2c73;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #2083fe;
  text-decoration: none;
}

.btn-link:hover {
  color: #015ed1;
  text-decoration: underline;
}

.btn-link:focus {
  text-decoration: underline;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 0.8rem 1.85rem;
  font-size: 1.1rem;
  border-radius: 0.3rem;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 0.3rem 0.6rem;
  font-size: 0.85rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 1rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.95rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fbfbfb;
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1500px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #fbfbfb;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #495057;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #454e9e;
  text-decoration: none;
  background-color: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #454e9e;
  text-decoration: none;
  background-color: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 1rem 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  color: #222;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #495057;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: #fbfbfb;
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #454e9e;
  background-color: none;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: #fbfbfb;
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.825rem;
  padding-left: 0.825rem;
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-right: 0.45rem;
  padding-left: 0.45rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-right: 1.3875rem;
  padding-left: 1.3875rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #1e2e50;
  background-color: #fff;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1500px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #3d448b;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #3d448b;
}

.navbar-light .navbar-nav .nav-link {
  color: #212529;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #3d448b;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: #3d448b;
}

.navbar-light .navbar-toggler {
  color: #212529;
  border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23212529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #212529;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: #3d448b;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #e2e5e8;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(30, 46, 80, 0.09);
  border-radius: 0.25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.75rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.75rem;
}

.card-header {
  padding: 0.75rem 1.75rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(30, 46, 80, 0.09);
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-footer {
  padding: 0.75rem 1.75rem;
  background-color: transparent;
  border-top: 0 solid rgba(30, 46, 80, 0.09);
}

.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.875rem;
  margin-bottom: -0.75rem;
  margin-left: -0.875rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.875rem;
  margin-left: -0.875rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.25rem;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-group>.card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-header,
  .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-footer,
  .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-header,
  .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-footer,
  .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: none;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item.active {
  color: #1f2c73;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #1e2e50;
  background-color: transparent;
  border: 0 solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #1e2e50;
  text-decoration: none;
  background-color: #f8f9fa;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #015ed1;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(32, 131, 254, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #f6f9fc;
  background-color: #1e2e50;
  border-color: #1e2e50;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.85rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #0c2f5b;
  background-color: #c1dcff;
  border-color: #c1dcff;
}

.alert-primary .alert-link {
  color: #06182e;
}

.alert-secondary {
  color: #202734;
  background-color: #d1d6e0;
  border-color: #d1d6e0;
}

.alert-secondary .alert-link {
  color: #0d0f14;
}

.alert-success {
  color: #134434;
  background-color: #c6ede0;
  border-color: #c6ede0;
}

.alert-success .alert-link {
  color: #081c16;
}

.alert-info {
  color: #09425c;
  background-color: #beebff;
  border-color: #beebff;
}

.alert-info .alert-link {
  color: #04212e;
}

.alert-warning {
  color: #5b480d;
  background-color: #feefc2;
  border-color: #feefc2;
}

.alert-warning .alert-link {
  color: #2e2507;
}

.alert-danger {
  color: #441818;
  background-color: #edcaca;
  border-color: #edcaca;
}

.alert-danger .alert-link {
  color: #1e0b0b;
}

.alert-light {
  color: #595a5b;
  background-color: #fcfdfe;
  border-color: #fcfdfe;
}

.alert-light .alert-link {
  color: #404141;
}

.alert-dark {
  color: #0b111d;
  background-color: #c0c4ce;
  border-color: #c0c4ce;
}

.alert-dark .alert-link {
  color: #000;
}

.alert-purple {
  color: #252954;
  background-color: #d4d8f9;
  border-color: #d4d8f9;
}

.alert-purple .alert-link {
  color: #151831;
}

.alert-sky {
  color: #54585c;
  background-color: #f9fcff;
  border-color: #f9fcff;
}

.alert-sky .alert-link {
  color: #3c3f41;
}

.alert-pistachio {
  color: #505858;
  background-color: #f5fcfc;
  border-color: #f5fcfc;
}

.alert-pistachio .alert-link {
  color: #383d3d;
}

.alert-darkblue {
  color: #0b1029;
  background-color: #c0c4d8;
  border-color: #c0c4d8;
}

.alert-darkblue .alert-link {
  color: #000001;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2083fe;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid rgba(30, 46, 80, 0.09);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2083fe;
  border-color: #2083fe;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1500px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #114484;
  background-color: #c1dcff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #114484;
  background-color: #a8ceff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #114484;
  border-color: #114484;
}

.list-group-item-secondary {
  color: #2f384b;
  background-color: #d1d6e0;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #2f384b;
  background-color: #c2c8d6;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #2f384b;
  border-color: #2f384b;
}

.list-group-item-success {
  color: #1b624b;
  background-color: #c6ede0;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #1b624b;
  background-color: #b3e7d5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1b624b;
  border-color: #1b624b;
}

.list-group-item-info {
  color: #0c5f85;
  background-color: #beebff;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5f85;
  background-color: #a5e3ff;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5f85;
  border-color: #0c5f85;
}

.list-group-item-warning {
  color: #846713;
  background-color: #feefc2;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #846713;
  background-color: #fee8a9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #846713;
  border-color: #846713;
}

.list-group-item-danger {
  color: #622222;
  background-color: #edcaca;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #622222;
  background-color: #e7b7b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #622222;
  border-color: #622222;
}

.list-group-item-light {
  color: #808183;
  background-color: #fcfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #808183;
  background-color: #e9f0f8;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #808183;
  border-color: #808183;
}

.list-group-item-dark {
  color: #10182a;
  background-color: #c0c4ce;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #10182a;
  background-color: #b2b7c3;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #10182a;
  border-color: #10182a;
}

.list-group-item-purple {
  color: #353b79;
  background-color: #d4d8f9;
}

.list-group-item-purple.list-group-item-action:focus,
.list-group-item-purple.list-group-item-action:hover {
  color: #353b79;
  background-color: #bec4f6;
}

.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #353b79;
  border-color: #353b79;
}

.list-group-item-sky {
  color: #797f85;
  background-color: #f9fcff;
}

.list-group-item-sky.list-group-item-action:focus,
.list-group-item-sky.list-group-item-action:hover {
  color: #797f85;
  background-color: #e0efff;
}

.list-group-item-sky.list-group-item-action.active {
  color: #fff;
  background-color: #797f85;
  border-color: #797f85;
}

.list-group-item-pistachio {
  color: #737f7f;
  background-color: #f5fcfc;
}

.list-group-item-pistachio.list-group-item-action:focus,
.list-group-item-pistachio.list-group-item-action:hover {
  color: #737f7f;
  background-color: #e1f6f6;
}

.list-group-item-pistachio.list-group-item-action.active {
  color: #fff;
  background-color: #737f7f;
  border-color: #737f7f;
}

.list-group-item-darkblue {
  color: #10173c;
  background-color: #c0c4d8;
}

.list-group-item-darkblue.list-group-item-action:focus,
.list-group-item-darkblue.list-group-item-action:hover {
  color: #10173c;
  background-color: #b0b5ce;
}

.list-group-item-darkblue.list-group-item-action.active {
  color: #fff;
  background-color: #10173c;
  border-color: #10173c;
}

.btn-close {
  box-sizing: content-box;
  width: 0.7em;
  height: 0.7em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") no-repeat center center/.7em auto;
  background-clip: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(32, 131, 254, 0.25);
  opacity: 1;
}

.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1.25rem rgba(35, 38, 45, 0.125);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #1e2e50;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1499.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: SpartanMB, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.85rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow,
.bs-tooltip-right .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-right .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow,
.bs-tooltip-left .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-left .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: SpartanMB, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.85rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"]>.popover-arrow,
.bs-popover-top>.popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="top"]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"]>.popover-arrow,
.bs-popover-right>.popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^="right"]>.popover-arrow::before,
.bs-popover-right>.popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="right"]>.popover-arrow::after,
.bs-popover-right>.popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="bottom"]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"]>.popover-arrow,
.bs-popover-left>.popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^="left"]>.popover-arrow::before,
.bs-popover-left>.popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="left"]>.popover-arrow::after,
.bs-popover-left>.popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators li {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #2083fe;
}

.link-primary:focus,
.link-primary:hover {
  color: #015ed1;
}

.link-secondary {
  color: #5a6c90;
}

.link-secondary:focus,
.link-secondary:hover {
  color: #3d4961;
}

.link-success {
  color: #34bd91;
}

.link-success:focus,
.link-success:hover {
  color: #238163;
}

.link-info {
  color: #18b7ff;
}

.link-info:focus,
.link-info:hover {
  color: #008bcb;
}

.link-warning {
  color: #fdc724;
}

.link-warning:focus,
.link-warning:hover {
  color: #feda70;
}

.link-danger {
  color: #bd4242;
}

.link-danger:focus,
.link-danger:hover {
  color: #842e2e;
}

.link-light {
  color: #f6f9fc;
}

.link-light:focus,
.link-light:hover {
  color: #fff;
}

.link-dark {
  color: #1e2e50;
}

.link-dark:focus,
.link-dark:hover {
  color: #090e18;
}

.link-purple {
  color: #6672e8;
}

.link-purple:focus,
.link-purple:hover {
  color: #2335de;
}

.link-sky {
  color: #e9f4ff;
}

.link-sky:focus,
.link-sky:hover {
  color: #fff;
}

.link-pistachio {
  color: #ddf5f4;
}

.link-pistachio:focus,
.link-pistachio:hover {
  color: #fff;
}

.link-darkblue {
  color: #1f2c73;
}

.link-darkblue:focus,
.link-darkblue:hover {
  color: #0f1537;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1500px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1.25rem rgba(35, 38, 45, 0.125) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(35, 38, 45, 0.09) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.275) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.left-0 {
  left: 0 !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

.right-0 {
  right: 0 !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translateX(-50%) translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2083fe !important;
}

.border-secondary {
  border-color: #5a6c90 !important;
}

.border-success {
  border-color: #34bd91 !important;
}

.border-info {
  border-color: #18b7ff !important;
}

.border-warning {
  border-color: #fdc724 !important;
}

.border-danger {
  border-color: #bd4242 !important;
}

.border-light {
  border-color: #f6f9fc !important;
}

.border-dark {
  border-color: #1e2e50 !important;
}

.border-purple {
  border-color: #6672e8 !important;
}

.border-sky {
  border-color: #e9f4ff !important;
}

.border-pistachio {
  border-color: #ddf5f4 !important;
}

.border-darkblue {
  border-color: #1f2c73 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.m-10 {
  margin: 12rem !important;
}

.m-11 {
  margin: 14rem !important;
}

.m-12 {
  margin: 16rem !important;
}

.m-13 {
  margin: 18rem !important;
}

.m-14 {
  margin: 20rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-10 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-11 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-12 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-13 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-14 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-10 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-11 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-12 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-13 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-14 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 10rem !important;
}

.mt-10 {
  margin-top: 12rem !important;
}

.mt-11 {
  margin-top: 14rem !important;
}

.mt-12 {
  margin-top: 16rem !important;
}

.mt-13 {
  margin-top: 18rem !important;
}

.mt-14 {
  margin-top: 20rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-6 {
  margin-right: 4rem !important;
}

.mr-7 {
  margin-right: 6rem !important;
}

.mr-8 {
  margin-right: 8rem !important;
}

.mr-9 {
  margin-right: 10rem !important;
}

.mr-10 {
  margin-right: 12rem !important;
}

.mr-11 {
  margin-right: 14rem !important;
}

.mr-12 {
  margin-right: 16rem !important;
}

.mr-13 {
  margin-right: 18rem !important;
}

.mr-14 {
  margin-right: 20rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 10rem !important;
}

.mb-10 {
  margin-bottom: 12rem !important;
}

.mb-11 {
  margin-bottom: 14rem !important;
}

.mb-12 {
  margin-bottom: 16rem !important;
}

.mb-13 {
  margin-bottom: 18rem !important;
}

.mb-14 {
  margin-bottom: 20rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.ml-6 {
  margin-left: 4rem !important;
}

.ml-7 {
  margin-left: 6rem !important;
}

.ml-8 {
  margin-left: 8rem !important;
}

.ml-9 {
  margin-left: 10rem !important;
}

.ml-10 {
  margin-left: 12rem !important;
}

.ml-11 {
  margin-left: 14rem !important;
}

.ml-12 {
  margin-left: 16rem !important;
}

.ml-13 {
  margin-left: 18rem !important;
}

.ml-14 {
  margin-left: 20rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.m-n8 {
  margin: -8rem !important;
}

.m-n9 {
  margin: -10rem !important;
}

.m-n10 {
  margin: -12rem !important;
}

.m-n11 {
  margin: -14rem !important;
}

.m-n12 {
  margin: -16rem !important;
}

.m-n13 {
  margin: -18rem !important;
}

.m-n14 {
  margin: -20rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n8 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n9 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n10 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.mx-n11 {
  margin-right: -14rem !important;
  margin-left: -14rem !important;
}

.mx-n12 {
  margin-right: -16rem !important;
  margin-left: -16rem !important;
}

.mx-n13 {
  margin-right: -18rem !important;
  margin-left: -18rem !important;
}

.mx-n14 {
  margin-right: -20rem !important;
  margin-left: -20rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n8 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n9 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n10 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n11 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.my-n12 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n13 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.my-n14 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -8rem !important;
}

.mt-n9 {
  margin-top: -10rem !important;
}

.mt-n10 {
  margin-top: -12rem !important;
}

.mt-n11 {
  margin-top: -14rem !important;
}

.mt-n12 {
  margin-top: -16rem !important;
}

.mt-n13 {
  margin-top: -18rem !important;
}

.mt-n14 {
  margin-top: -20rem !important;
}

.mr-n1 {
  margin-right: -0.25rem !important;
}

.mr-n2 {
  margin-right: -0.5rem !important;
}

.mr-n3 {
  margin-right: -1rem !important;
}

.mr-n4 {
  margin-right: -1.5rem !important;
}

.mr-n5 {
  margin-right: -3rem !important;
}

.mr-n6 {
  margin-right: -4rem !important;
}

.mr-n7 {
  margin-right: -6rem !important;
}

.mr-n8 {
  margin-right: -8rem !important;
}

.mr-n9 {
  margin-right: -10rem !important;
}

.mr-n10 {
  margin-right: -12rem !important;
}

.mr-n11 {
  margin-right: -14rem !important;
}

.mr-n12 {
  margin-right: -16rem !important;
}

.mr-n13 {
  margin-right: -18rem !important;
}

.mr-n14 {
  margin-right: -20rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.mb-n8 {
  margin-bottom: -8rem !important;
}

.mb-n9 {
  margin-bottom: -10rem !important;
}

.mb-n10 {
  margin-bottom: -12rem !important;
}

.mb-n11 {
  margin-bottom: -14rem !important;
}

.mb-n12 {
  margin-bottom: -16rem !important;
}

.mb-n13 {
  margin-bottom: -18rem !important;
}

.mb-n14 {
  margin-bottom: -20rem !important;
}

.ml-n1 {
  margin-left: -0.25rem !important;
}

.ml-n2 {
  margin-left: -0.5rem !important;
}

.ml-n3 {
  margin-left: -1rem !important;
}

.ml-n4 {
  margin-left: -1.5rem !important;
}

.ml-n5 {
  margin-left: -3rem !important;
}

.ml-n6 {
  margin-left: -4rem !important;
}

.ml-n7 {
  margin-left: -6rem !important;
}

.ml-n8 {
  margin-left: -8rem !important;
}

.ml-n9 {
  margin-left: -10rem !important;
}

.ml-n10 {
  margin-left: -12rem !important;
}

.ml-n11 {
  margin-left: -14rem !important;
}

.ml-n12 {
  margin-left: -16rem !important;
}

.ml-n13 {
  margin-left: -18rem !important;
}

.ml-n14 {
  margin-left: -20rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.p-10 {
  padding: 12rem !important;
}

.p-11 {
  padding: 14rem !important;
}

.p-12 {
  padding: 16rem !important;
}

.p-13 {
  padding: 18rem !important;
}

.p-14 {
  padding: 20rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-10 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-11 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-12 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-13 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.px-14 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-10 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-11 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-12 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-13 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-14 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-9 {
  padding-top: 10rem !important;
}

.pt-10 {
  padding-top: 12rem !important;
}

.pt-11 {
  padding-top: 14rem !important;
}

.pt-12 {
  padding-top: 16rem !important;
}

.pt-13 {
  padding-top: 18rem !important;
}

.pt-14 {
  padding-top: 20rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pr-6 {
  padding-right: 4rem !important;
}

.pr-7 {
  padding-right: 6rem !important;
}

.pr-8 {
  padding-right: 8rem !important;
}

.pr-9 {
  padding-right: 10rem !important;
}

.pr-10 {
  padding-right: 12rem !important;
}

.pr-11 {
  padding-right: 14rem !important;
}

.pr-12 {
  padding-right: 16rem !important;
}

.pr-13 {
  padding-right: 18rem !important;
}

.pr-14 {
  padding-right: 20rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-9 {
  padding-bottom: 10rem !important;
}

.pb-10 {
  padding-bottom: 12rem !important;
}

.pb-11 {
  padding-bottom: 14rem !important;
}

.pb-12 {
  padding-bottom: 16rem !important;
}

.pb-13 {
  padding-bottom: 18rem !important;
}

.pb-14 {
  padding-bottom: 20rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pl-6 {
  padding-left: 4rem !important;
}

.pl-7 {
  padding-left: 6rem !important;
}

.pl-8 {
  padding-left: 8rem !important;
}

.pl-9 {
  padding-left: 10rem !important;
}

.pl-10 {
  padding-left: 12rem !important;
}

.pl-11 {
  padding-left: 14rem !important;
}

.pl-12 {
  padding-left: 16rem !important;
}

.pl-13 {
  padding-left: 18rem !important;
}

.pl-14 {
  padding-left: 20rem !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #2083fe !important;
}

.text-secondary {
  color: #5a6c90 !important;
}

.text-success {
  color: #34bd91 !important;
}

.text-info {
  color: #18b7ff !important;
}

.text-warning {
  color: #fdc724 !important;
}

.text-danger {
  color: #bd4242 !important;
}

.text-light {
  color: #f6f9fc !important;
}

.text-dark {
  color: #1e2e50 !important;
}

.text-purple {
  color: #6672e8 !important;
}

.text-sky {
  color: #e9f4ff !important;
}

.text-pistachio {
  color: #ddf5f4 !important;
}

.text-darkblue {
  color: #1f2c73 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #7184ad !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #2083fe !important;
}

.bg-secondary {
  background-color: #5a6c90 !important;
}

.bg-success {
  background-color: #34bd91 !important;
}

.bg-info {
  background-color: #18b7ff !important;
}

.bg-warning {
  background-color: #fdc724 !important;
}

.bg-danger {
  background-color: #bd4242 !important;
}

.bg-light {
  background-color: #f6f9fc !important;
}

.bg-dark {
  background-color: #1e2e50 !important;
}

.bg-purple {
  background-color: #6672e8 !important;
}

.bg-sky {
  background-color: #e9f4ff !important;
}

.bg-pistachio {
  background-color: #ddf5f4 !important;
}

.bg-darkblue {
  background-color: #1f2c73 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.bg-gray-100 {
  background: #f8f9fa !important;
}

.bg-gray-200 {
  background: #e9ecef !important;
}

.bg-gray-300 {
  background: #dee2e6 !important;
}

.bg-gray-400 {
  background: #ced4da !important;
}

.bg-gray-500 {
  background: #adb5bd !important;
}

.bg-gray-600 {
  background: #6c757d !important;
}

.bg-gray-700 {
  background: #495057 !important;
}

.bg-gray-800 {
  background: #343a40 !important;
}

.bg-gray-900 {
  background: #212529 !important;
}

.text-gray-100 {
  color: #f8f9fa !important;
}

.text-gray-200 {
  color: #e9ecef !important;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

.text-gray-500 {
  color: #adb5bd !important;
}

.text-gray-600 {
  color: #6c757d !important;
}

.text-gray-700 {
  color: #495057 !important;
}

.text-gray-800 {
  color: #343a40 !important;
}

.text-gray-900 {
  color: #212529 !important;
}

.border-gray-100 {
  border-color: #f8f9fa !important;
}

.border-gray-200 {
  border-color: #e9ecef !important;
}

.border-gray-300 {
  border-color: #dee2e6 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-gray-500 {
  border-color: #adb5bd !important;
}

.border-gray-600 {
  border-color: #6c757d !important;
}

.border-gray-700 {
  border-color: #495057 !important;
}

.border-gray-800 {
  border-color: #343a40 !important;
}

.border-gray-900 {
  border-color: #212529 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.shadow-light {
  box-shadow: 0 0.5rem 1.25rem rgba(35, 38, 45, 0.05) !important;
}

.shadow-light-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(35, 38, 45, 0.05) !important;
}

.shadow-light-lg {
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.05) !important;
}

.font-size-xs {
  font-size: 0.7rem !important;
}

.font-size-sm {
  font-size: 0.85rem !important;
}

.font-size-normal {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.25rem !important;
}

.letter-spacing-lg {
  letter-spacing: 2px !important;
}

.letter-spacing-xl {
  letter-spacing: 5px !important;
}

.letter-spacing-xxl {
  letter-spacing: 10px !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index--1 {
  z-index: -1 !important;
}

.z-index--2 {
  z-index: -2 !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-550 {
  width: 550px !important;
}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-700 {
  width: 700px !important;
}

.h-150 {
  height: 150px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-350 {
  height: 350px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-550 {
  height: 550px !important;
}

.h-600 {
  height: 600px !important;
}

.h-650 {
  height: 650px !important;
}

.h-700 {
  height: 700px !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .h-sm-10 {
    height: 10% !important;
  }

  .h-sm-20 {
    height: 20% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-30 {
    height: 30% !important;
  }

  .h-sm-40 {
    height: 40% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-60 {
    height: 60% !important;
  }

  .h-sm-70 {
    height: 70% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-80 {
    height: 80% !important;
  }

  .h-sm-90 {
    height: 90% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 4rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .m-sm-8 {
    margin: 8rem !important;
  }

  .m-sm-9 {
    margin: 10rem !important;
  }

  .m-sm-10 {
    margin: 12rem !important;
  }

  .m-sm-11 {
    margin: 14rem !important;
  }

  .m-sm-12 {
    margin: 16rem !important;
  }

  .m-sm-13 {
    margin: 18rem !important;
  }

  .m-sm-14 {
    margin: 20rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-sm-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-sm-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-sm-13 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-sm-14 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-sm-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-sm-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-sm-13 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-sm-14 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 4rem !important;
  }

  .mt-sm-7 {
    margin-top: 6rem !important;
  }

  .mt-sm-8 {
    margin-top: 8rem !important;
  }

  .mt-sm-9 {
    margin-top: 10rem !important;
  }

  .mt-sm-10 {
    margin-top: 12rem !important;
  }

  .mt-sm-11 {
    margin-top: 14rem !important;
  }

  .mt-sm-12 {
    margin-top: 16rem !important;
  }

  .mt-sm-13 {
    margin-top: 18rem !important;
  }

  .mt-sm-14 {
    margin-top: 20rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mr-sm-3 {
    margin-right: 1rem !important;
  }

  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mr-sm-5 {
    margin-right: 3rem !important;
  }

  .mr-sm-6 {
    margin-right: 4rem !important;
  }

  .mr-sm-7 {
    margin-right: 6rem !important;
  }

  .mr-sm-8 {
    margin-right: 8rem !important;
  }

  .mr-sm-9 {
    margin-right: 10rem !important;
  }

  .mr-sm-10 {
    margin-right: 12rem !important;
  }

  .mr-sm-11 {
    margin-right: 14rem !important;
  }

  .mr-sm-12 {
    margin-right: 16rem !important;
  }

  .mr-sm-13 {
    margin-right: 18rem !important;
  }

  .mr-sm-14 {
    margin-right: 20rem !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 16rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 18rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 20rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ml-sm-3 {
    margin-left: 1rem !important;
  }

  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ml-sm-5 {
    margin-left: 3rem !important;
  }

  .ml-sm-6 {
    margin-left: 4rem !important;
  }

  .ml-sm-7 {
    margin-left: 6rem !important;
  }

  .ml-sm-8 {
    margin-left: 8rem !important;
  }

  .ml-sm-9 {
    margin-left: 10rem !important;
  }

  .ml-sm-10 {
    margin-left: 12rem !important;
  }

  .ml-sm-11 {
    margin-left: 14rem !important;
  }

  .ml-sm-12 {
    margin-left: 16rem !important;
  }

  .ml-sm-13 {
    margin-left: 18rem !important;
  }

  .ml-sm-14 {
    margin-left: 20rem !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .m-sm-n6 {
    margin: -4rem !important;
  }

  .m-sm-n7 {
    margin: -6rem !important;
  }

  .m-sm-n8 {
    margin: -8rem !important;
  }

  .m-sm-n9 {
    margin: -10rem !important;
  }

  .m-sm-n10 {
    margin: -12rem !important;
  }

  .m-sm-n11 {
    margin: -14rem !important;
  }

  .m-sm-n12 {
    margin: -16rem !important;
  }

  .m-sm-n13 {
    margin: -18rem !important;
  }

  .m-sm-n14 {
    margin: -20rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-sm-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-sm-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-sm-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-sm-n13 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }

  .mx-sm-n14 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-sm-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-sm-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-sm-n13 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-sm-n14 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .mt-sm-n6 {
    margin-top: -4rem !important;
  }

  .mt-sm-n7 {
    margin-top: -6rem !important;
  }

  .mt-sm-n8 {
    margin-top: -8rem !important;
  }

  .mt-sm-n9 {
    margin-top: -10rem !important;
  }

  .mt-sm-n10 {
    margin-top: -12rem !important;
  }

  .mt-sm-n11 {
    margin-top: -14rem !important;
  }

  .mt-sm-n12 {
    margin-top: -16rem !important;
  }

  .mt-sm-n13 {
    margin-top: -18rem !important;
  }

  .mt-sm-n14 {
    margin-top: -20rem !important;
  }

  .mr-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-sm-n3 {
    margin-right: -1rem !important;
  }

  .mr-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mr-sm-n5 {
    margin-right: -3rem !important;
  }

  .mr-sm-n6 {
    margin-right: -4rem !important;
  }

  .mr-sm-n7 {
    margin-right: -6rem !important;
  }

  .mr-sm-n8 {
    margin-right: -8rem !important;
  }

  .mr-sm-n9 {
    margin-right: -10rem !important;
  }

  .mr-sm-n10 {
    margin-right: -12rem !important;
  }

  .mr-sm-n11 {
    margin-right: -14rem !important;
  }

  .mr-sm-n12 {
    margin-right: -16rem !important;
  }

  .mr-sm-n13 {
    margin-right: -18rem !important;
  }

  .mr-sm-n14 {
    margin-right: -20rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -16rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -18rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -20rem !important;
  }

  .ml-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-sm-n3 {
    margin-left: -1rem !important;
  }

  .ml-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ml-sm-n5 {
    margin-left: -3rem !important;
  }

  .ml-sm-n6 {
    margin-left: -4rem !important;
  }

  .ml-sm-n7 {
    margin-left: -6rem !important;
  }

  .ml-sm-n8 {
    margin-left: -8rem !important;
  }

  .ml-sm-n9 {
    margin-left: -10rem !important;
  }

  .ml-sm-n10 {
    margin-left: -12rem !important;
  }

  .ml-sm-n11 {
    margin-left: -14rem !important;
  }

  .ml-sm-n12 {
    margin-left: -16rem !important;
  }

  .ml-sm-n13 {
    margin-left: -18rem !important;
  }

  .ml-sm-n14 {
    margin-left: -20rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 4rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .p-sm-8 {
    padding: 8rem !important;
  }

  .p-sm-9 {
    padding: 10rem !important;
  }

  .p-sm-10 {
    padding: 12rem !important;
  }

  .p-sm-11 {
    padding: 14rem !important;
  }

  .p-sm-12 {
    padding: 16rem !important;
  }

  .p-sm-13 {
    padding: 18rem !important;
  }

  .p-sm-14 {
    padding: 20rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-sm-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-sm-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-sm-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-sm-13 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .px-sm-14 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-sm-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-sm-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-sm-13 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-sm-14 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 4rem !important;
  }

  .pt-sm-7 {
    padding-top: 6rem !important;
  }

  .pt-sm-8 {
    padding-top: 8rem !important;
  }

  .pt-sm-9 {
    padding-top: 10rem !important;
  }

  .pt-sm-10 {
    padding-top: 12rem !important;
  }

  .pt-sm-11 {
    padding-top: 14rem !important;
  }

  .pt-sm-12 {
    padding-top: 16rem !important;
  }

  .pt-sm-13 {
    padding-top: 18rem !important;
  }

  .pt-sm-14 {
    padding-top: 20rem !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pr-sm-3 {
    padding-right: 1rem !important;
  }

  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pr-sm-5 {
    padding-right: 3rem !important;
  }

  .pr-sm-6 {
    padding-right: 4rem !important;
  }

  .pr-sm-7 {
    padding-right: 6rem !important;
  }

  .pr-sm-8 {
    padding-right: 8rem !important;
  }

  .pr-sm-9 {
    padding-right: 10rem !important;
  }

  .pr-sm-10 {
    padding-right: 12rem !important;
  }

  .pr-sm-11 {
    padding-right: 14rem !important;
  }

  .pr-sm-12 {
    padding-right: 16rem !important;
  }

  .pr-sm-13 {
    padding-right: 18rem !important;
  }

  .pr-sm-14 {
    padding-right: 20rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 12rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 14rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 16rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 18rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 20rem !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }

  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }

  .pl-sm-3 {
    padding-left: 1rem !important;
  }

  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }

  .pl-sm-5 {
    padding-left: 3rem !important;
  }

  .pl-sm-6 {
    padding-left: 4rem !important;
  }

  .pl-sm-7 {
    padding-left: 6rem !important;
  }

  .pl-sm-8 {
    padding-left: 8rem !important;
  }

  .pl-sm-9 {
    padding-left: 10rem !important;
  }

  .pl-sm-10 {
    padding-left: 12rem !important;
  }

  .pl-sm-11 {
    padding-left: 14rem !important;
  }

  .pl-sm-12 {
    padding-left: 16rem !important;
  }

  .pl-sm-13 {
    padding-left: 18rem !important;
  }

  .pl-sm-14 {
    padding-left: 20rem !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .w-sm-150 {
    width: 150px !important;
  }

  .w-sm-200 {
    width: 200px !important;
  }

  .w-sm-250 {
    width: 250px !important;
  }

  .w-sm-300 {
    width: 300px !important;
  }

  .w-sm-350 {
    width: 350px !important;
  }

  .w-sm-400 {
    width: 400px !important;
  }

  .w-sm-450 {
    width: 450px !important;
  }

  .w-sm-500 {
    width: 500px !important;
  }

  .w-sm-550 {
    width: 550px !important;
  }

  .w-sm-600 {
    width: 600px !important;
  }

  .w-sm-650 {
    width: 650px !important;
  }

  .w-sm-700 {
    width: 700px !important;
  }

  .h-sm-150 {
    height: 150px !important;
  }

  .h-sm-200 {
    height: 200px !important;
  }

  .h-sm-250 {
    height: 250px !important;
  }

  .h-sm-300 {
    height: 300px !important;
  }

  .h-sm-350 {
    height: 350px !important;
  }

  .h-sm-400 {
    height: 400px !important;
  }

  .h-sm-450 {
    height: 450px !important;
  }

  .h-sm-500 {
    height: 500px !important;
  }

  .h-sm-550 {
    height: 550px !important;
  }

  .h-sm-600 {
    height: 600px !important;
  }

  .h-sm-650 {
    height: 650px !important;
  }

  .h-sm-700 {
    height: 700px !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .h-md-10 {
    height: 10% !important;
  }

  .h-md-20 {
    height: 20% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-30 {
    height: 30% !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .h-md-70 {
    height: 70% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-80 {
    height: 80% !important;
  }

  .h-md-90 {
    height: 90% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 4rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .m-md-8 {
    margin: 8rem !important;
  }

  .m-md-9 {
    margin: 10rem !important;
  }

  .m-md-10 {
    margin: 12rem !important;
  }

  .m-md-11 {
    margin: 14rem !important;
  }

  .m-md-12 {
    margin: 16rem !important;
  }

  .m-md-13 {
    margin: 18rem !important;
  }

  .m-md-14 {
    margin: 20rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-md-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-md-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-md-13 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-md-14 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-md-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-md-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-md-13 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-md-14 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 4rem !important;
  }

  .mt-md-7 {
    margin-top: 6rem !important;
  }

  .mt-md-8 {
    margin-top: 8rem !important;
  }

  .mt-md-9 {
    margin-top: 10rem !important;
  }

  .mt-md-10 {
    margin-top: 12rem !important;
  }

  .mt-md-11 {
    margin-top: 14rem !important;
  }

  .mt-md-12 {
    margin-top: 16rem !important;
  }

  .mt-md-13 {
    margin-top: 18rem !important;
  }

  .mt-md-14 {
    margin-top: 20rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-1 {
    margin-right: 0.25rem !important;
  }

  .mr-md-2 {
    margin-right: 0.5rem !important;
  }

  .mr-md-3 {
    margin-right: 1rem !important;
  }

  .mr-md-4 {
    margin-right: 1.5rem !important;
  }

  .mr-md-5 {
    margin-right: 3rem !important;
  }

  .mr-md-6 {
    margin-right: 4rem !important;
  }

  .mr-md-7 {
    margin-right: 6rem !important;
  }

  .mr-md-8 {
    margin-right: 8rem !important;
  }

  .mr-md-9 {
    margin-right: 10rem !important;
  }

  .mr-md-10 {
    margin-right: 12rem !important;
  }

  .mr-md-11 {
    margin-right: 14rem !important;
  }

  .mr-md-12 {
    margin-right: 16rem !important;
  }

  .mr-md-13 {
    margin-right: 18rem !important;
  }

  .mr-md-14 {
    margin-right: 20rem !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 4rem !important;
  }

  .mb-md-7 {
    margin-bottom: 6rem !important;
  }

  .mb-md-8 {
    margin-bottom: 8rem !important;
  }

  .mb-md-9 {
    margin-bottom: 10rem !important;
  }

  .mb-md-10 {
    margin-bottom: 12rem !important;
  }

  .mb-md-11 {
    margin-bottom: 14rem !important;
  }

  .mb-md-12 {
    margin-bottom: 16rem !important;
  }

  .mb-md-13 {
    margin-bottom: 18rem !important;
  }

  .mb-md-14 {
    margin-bottom: 20rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-1 {
    margin-left: 0.25rem !important;
  }

  .ml-md-2 {
    margin-left: 0.5rem !important;
  }

  .ml-md-3 {
    margin-left: 1rem !important;
  }

  .ml-md-4 {
    margin-left: 1.5rem !important;
  }

  .ml-md-5 {
    margin-left: 3rem !important;
  }

  .ml-md-6 {
    margin-left: 4rem !important;
  }

  .ml-md-7 {
    margin-left: 6rem !important;
  }

  .ml-md-8 {
    margin-left: 8rem !important;
  }

  .ml-md-9 {
    margin-left: 10rem !important;
  }

  .ml-md-10 {
    margin-left: 12rem !important;
  }

  .ml-md-11 {
    margin-left: 14rem !important;
  }

  .ml-md-12 {
    margin-left: 16rem !important;
  }

  .ml-md-13 {
    margin-left: 18rem !important;
  }

  .ml-md-14 {
    margin-left: 20rem !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .m-md-n6 {
    margin: -4rem !important;
  }

  .m-md-n7 {
    margin: -6rem !important;
  }

  .m-md-n8 {
    margin: -8rem !important;
  }

  .m-md-n9 {
    margin: -10rem !important;
  }

  .m-md-n10 {
    margin: -12rem !important;
  }

  .m-md-n11 {
    margin: -14rem !important;
  }

  .m-md-n12 {
    margin: -16rem !important;
  }

  .m-md-n13 {
    margin: -18rem !important;
  }

  .m-md-n14 {
    margin: -20rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-md-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-md-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-md-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-md-n13 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }

  .mx-md-n14 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-md-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-md-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-md-n13 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-md-n14 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .mt-md-n6 {
    margin-top: -4rem !important;
  }

  .mt-md-n7 {
    margin-top: -6rem !important;
  }

  .mt-md-n8 {
    margin-top: -8rem !important;
  }

  .mt-md-n9 {
    margin-top: -10rem !important;
  }

  .mt-md-n10 {
    margin-top: -12rem !important;
  }

  .mt-md-n11 {
    margin-top: -14rem !important;
  }

  .mt-md-n12 {
    margin-top: -16rem !important;
  }

  .mt-md-n13 {
    margin-top: -18rem !important;
  }

  .mt-md-n14 {
    margin-top: -20rem !important;
  }

  .mr-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-md-n3 {
    margin-right: -1rem !important;
  }

  .mr-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mr-md-n5 {
    margin-right: -3rem !important;
  }

  .mr-md-n6 {
    margin-right: -4rem !important;
  }

  .mr-md-n7 {
    margin-right: -6rem !important;
  }

  .mr-md-n8 {
    margin-right: -8rem !important;
  }

  .mr-md-n9 {
    margin-right: -10rem !important;
  }

  .mr-md-n10 {
    margin-right: -12rem !important;
  }

  .mr-md-n11 {
    margin-right: -14rem !important;
  }

  .mr-md-n12 {
    margin-right: -16rem !important;
  }

  .mr-md-n13 {
    margin-right: -18rem !important;
  }

  .mr-md-n14 {
    margin-right: -20rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -16rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -18rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -20rem !important;
  }

  .ml-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-md-n3 {
    margin-left: -1rem !important;
  }

  .ml-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ml-md-n5 {
    margin-left: -3rem !important;
  }

  .ml-md-n6 {
    margin-left: -4rem !important;
  }

  .ml-md-n7 {
    margin-left: -6rem !important;
  }

  .ml-md-n8 {
    margin-left: -8rem !important;
  }

  .ml-md-n9 {
    margin-left: -10rem !important;
  }

  .ml-md-n10 {
    margin-left: -12rem !important;
  }

  .ml-md-n11 {
    margin-left: -14rem !important;
  }

  .ml-md-n12 {
    margin-left: -16rem !important;
  }

  .ml-md-n13 {
    margin-left: -18rem !important;
  }

  .ml-md-n14 {
    margin-left: -20rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 4rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .p-md-8 {
    padding: 8rem !important;
  }

  .p-md-9 {
    padding: 10rem !important;
  }

  .p-md-10 {
    padding: 12rem !important;
  }

  .p-md-11 {
    padding: 14rem !important;
  }

  .p-md-12 {
    padding: 16rem !important;
  }

  .p-md-13 {
    padding: 18rem !important;
  }

  .p-md-14 {
    padding: 20rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-md-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-md-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-md-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-md-13 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .px-md-14 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-md-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-md-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-md-13 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-md-14 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 4rem !important;
  }

  .pt-md-7 {
    padding-top: 6rem !important;
  }

  .pt-md-8 {
    padding-top: 8rem !important;
  }

  .pt-md-9 {
    padding-top: 10rem !important;
  }

  .pt-md-10 {
    padding-top: 12rem !important;
  }

  .pt-md-11 {
    padding-top: 14rem !important;
  }

  .pt-md-12 {
    padding-top: 16rem !important;
  }

  .pt-md-13 {
    padding-top: 18rem !important;
  }

  .pt-md-14 {
    padding-top: 20rem !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pr-md-1 {
    padding-right: 0.25rem !important;
  }

  .pr-md-2 {
    padding-right: 0.5rem !important;
  }

  .pr-md-3 {
    padding-right: 1rem !important;
  }

  .pr-md-4 {
    padding-right: 1.5rem !important;
  }

  .pr-md-5 {
    padding-right: 3rem !important;
  }

  .pr-md-6 {
    padding-right: 4rem !important;
  }

  .pr-md-7 {
    padding-right: 6rem !important;
  }

  .pr-md-8 {
    padding-right: 8rem !important;
  }

  .pr-md-9 {
    padding-right: 10rem !important;
  }

  .pr-md-10 {
    padding-right: 12rem !important;
  }

  .pr-md-11 {
    padding-right: 14rem !important;
  }

  .pr-md-12 {
    padding-right: 16rem !important;
  }

  .pr-md-13 {
    padding-right: 18rem !important;
  }

  .pr-md-14 {
    padding-right: 20rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 4rem !important;
  }

  .pb-md-7 {
    padding-bottom: 6rem !important;
  }

  .pb-md-8 {
    padding-bottom: 8rem !important;
  }

  .pb-md-9 {
    padding-bottom: 10rem !important;
  }

  .pb-md-10 {
    padding-bottom: 12rem !important;
  }

  .pb-md-11 {
    padding-bottom: 14rem !important;
  }

  .pb-md-12 {
    padding-bottom: 16rem !important;
  }

  .pb-md-13 {
    padding-bottom: 18rem !important;
  }

  .pb-md-14 {
    padding-bottom: 20rem !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .pl-md-1 {
    padding-left: 0.25rem !important;
  }

  .pl-md-2 {
    padding-left: 0.5rem !important;
  }

  .pl-md-3 {
    padding-left: 1rem !important;
  }

  .pl-md-4 {
    padding-left: 1.5rem !important;
  }

  .pl-md-5 {
    padding-left: 3rem !important;
  }

  .pl-md-6 {
    padding-left: 4rem !important;
  }

  .pl-md-7 {
    padding-left: 6rem !important;
  }

  .pl-md-8 {
    padding-left: 8rem !important;
  }

  .pl-md-9 {
    padding-left: 10rem !important;
  }

  .pl-md-10 {
    padding-left: 12rem !important;
  }

  .pl-md-11 {
    padding-left: 14rem !important;
  }

  .pl-md-12 {
    padding-left: 16rem !important;
  }

  .pl-md-13 {
    padding-left: 18rem !important;
  }

  .pl-md-14 {
    padding-left: 20rem !important;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .w-md-150 {
    width: 150px !important;
  }

  .w-md-200 {
    width: 200px !important;
  }

  .w-md-250 {
    width: 250px !important;
  }

  .w-md-300 {
    width: 300px !important;
  }

  .w-md-350 {
    width: 350px !important;
  }

  .w-md-400 {
    width: 400px !important;
  }

  .w-md-450 {
    width: 450px !important;
  }

  .w-md-500 {
    width: 500px !important;
  }

  .w-md-550 {
    width: 550px !important;
  }

  .w-md-600 {
    width: 600px !important;
  }

  .w-md-650 {
    width: 650px !important;
  }

  .w-md-700 {
    width: 700px !important;
  }

  .h-md-150 {
    height: 150px !important;
  }

  .h-md-200 {
    height: 200px !important;
  }

  .h-md-250 {
    height: 250px !important;
  }

  .h-md-300 {
    height: 300px !important;
  }

  .h-md-350 {
    height: 350px !important;
  }

  .h-md-400 {
    height: 400px !important;
  }

  .h-md-450 {
    height: 450px !important;
  }

  .h-md-500 {
    height: 500px !important;
  }

  .h-md-550 {
    height: 550px !important;
  }

  .h-md-600 {
    height: 600px !important;
  }

  .h-md-650 {
    height: 650px !important;
  }

  .h-md-700 {
    height: 700px !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .h-lg-10 {
    height: 10% !important;
  }

  .h-lg-20 {
    height: 20% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-30 {
    height: 30% !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .h-lg-70 {
    height: 70% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-80 {
    height: 80% !important;
  }

  .h-lg-90 {
    height: 90% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 4rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .m-lg-8 {
    margin: 8rem !important;
  }

  .m-lg-9 {
    margin: 10rem !important;
  }

  .m-lg-10 {
    margin: 12rem !important;
  }

  .m-lg-11 {
    margin: 14rem !important;
  }

  .m-lg-12 {
    margin: 16rem !important;
  }

  .m-lg-13 {
    margin: 18rem !important;
  }

  .m-lg-14 {
    margin: 20rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-lg-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-lg-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-lg-13 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-lg-14 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-lg-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-lg-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-lg-13 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-lg-14 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 4rem !important;
  }

  .mt-lg-7 {
    margin-top: 6rem !important;
  }

  .mt-lg-8 {
    margin-top: 8rem !important;
  }

  .mt-lg-9 {
    margin-top: 10rem !important;
  }

  .mt-lg-10 {
    margin-top: 12rem !important;
  }

  .mt-lg-11 {
    margin-top: 14rem !important;
  }

  .mt-lg-12 {
    margin-top: 16rem !important;
  }

  .mt-lg-13 {
    margin-top: 18rem !important;
  }

  .mt-lg-14 {
    margin-top: 20rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mr-lg-3 {
    margin-right: 1rem !important;
  }

  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mr-lg-5 {
    margin-right: 3rem !important;
  }

  .mr-lg-6 {
    margin-right: 4rem !important;
  }

  .mr-lg-7 {
    margin-right: 6rem !important;
  }

  .mr-lg-8 {
    margin-right: 8rem !important;
  }

  .mr-lg-9 {
    margin-right: 10rem !important;
  }

  .mr-lg-10 {
    margin-right: 12rem !important;
  }

  .mr-lg-11 {
    margin-right: 14rem !important;
  }

  .mr-lg-12 {
    margin-right: 16rem !important;
  }

  .mr-lg-13 {
    margin-right: 18rem !important;
  }

  .mr-lg-14 {
    margin-right: 20rem !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 16rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 18rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 20rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ml-lg-3 {
    margin-left: 1rem !important;
  }

  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ml-lg-5 {
    margin-left: 3rem !important;
  }

  .ml-lg-6 {
    margin-left: 4rem !important;
  }

  .ml-lg-7 {
    margin-left: 6rem !important;
  }

  .ml-lg-8 {
    margin-left: 8rem !important;
  }

  .ml-lg-9 {
    margin-left: 10rem !important;
  }

  .ml-lg-10 {
    margin-left: 12rem !important;
  }

  .ml-lg-11 {
    margin-left: 14rem !important;
  }

  .ml-lg-12 {
    margin-left: 16rem !important;
  }

  .ml-lg-13 {
    margin-left: 18rem !important;
  }

  .ml-lg-14 {
    margin-left: 20rem !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .m-lg-n6 {
    margin: -4rem !important;
  }

  .m-lg-n7 {
    margin: -6rem !important;
  }

  .m-lg-n8 {
    margin: -8rem !important;
  }

  .m-lg-n9 {
    margin: -10rem !important;
  }

  .m-lg-n10 {
    margin: -12rem !important;
  }

  .m-lg-n11 {
    margin: -14rem !important;
  }

  .m-lg-n12 {
    margin: -16rem !important;
  }

  .m-lg-n13 {
    margin: -18rem !important;
  }

  .m-lg-n14 {
    margin: -20rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-lg-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-lg-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-lg-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-lg-n13 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }

  .mx-lg-n14 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-lg-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-lg-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-lg-n13 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-lg-n14 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .mt-lg-n6 {
    margin-top: -4rem !important;
  }

  .mt-lg-n7 {
    margin-top: -6rem !important;
  }

  .mt-lg-n8 {
    margin-top: -8rem !important;
  }

  .mt-lg-n9 {
    margin-top: -10rem !important;
  }

  .mt-lg-n10 {
    margin-top: -12rem !important;
  }

  .mt-lg-n11 {
    margin-top: -14rem !important;
  }

  .mt-lg-n12 {
    margin-top: -16rem !important;
  }

  .mt-lg-n13 {
    margin-top: -18rem !important;
  }

  .mt-lg-n14 {
    margin-top: -20rem !important;
  }

  .mr-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-lg-n3 {
    margin-right: -1rem !important;
  }

  .mr-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mr-lg-n5 {
    margin-right: -3rem !important;
  }

  .mr-lg-n6 {
    margin-right: -4rem !important;
  }

  .mr-lg-n7 {
    margin-right: -6rem !important;
  }

  .mr-lg-n8 {
    margin-right: -8rem !important;
  }

  .mr-lg-n9 {
    margin-right: -10rem !important;
  }

  .mr-lg-n10 {
    margin-right: -12rem !important;
  }

  .mr-lg-n11 {
    margin-right: -14rem !important;
  }

  .mr-lg-n12 {
    margin-right: -16rem !important;
  }

  .mr-lg-n13 {
    margin-right: -18rem !important;
  }

  .mr-lg-n14 {
    margin-right: -20rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -16rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -18rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -20rem !important;
  }

  .ml-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-lg-n3 {
    margin-left: -1rem !important;
  }

  .ml-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ml-lg-n5 {
    margin-left: -3rem !important;
  }

  .ml-lg-n6 {
    margin-left: -4rem !important;
  }

  .ml-lg-n7 {
    margin-left: -6rem !important;
  }

  .ml-lg-n8 {
    margin-left: -8rem !important;
  }

  .ml-lg-n9 {
    margin-left: -10rem !important;
  }

  .ml-lg-n10 {
    margin-left: -12rem !important;
  }

  .ml-lg-n11 {
    margin-left: -14rem !important;
  }

  .ml-lg-n12 {
    margin-left: -16rem !important;
  }

  .ml-lg-n13 {
    margin-left: -18rem !important;
  }

  .ml-lg-n14 {
    margin-left: -20rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 4rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .p-lg-8 {
    padding: 8rem !important;
  }

  .p-lg-9 {
    padding: 10rem !important;
  }

  .p-lg-10 {
    padding: 12rem !important;
  }

  .p-lg-11 {
    padding: 14rem !important;
  }

  .p-lg-12 {
    padding: 16rem !important;
  }

  .p-lg-13 {
    padding: 18rem !important;
  }

  .p-lg-14 {
    padding: 20rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-lg-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-lg-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-lg-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-lg-13 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .px-lg-14 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-lg-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-lg-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-lg-13 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-lg-14 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 4rem !important;
  }

  .pt-lg-7 {
    padding-top: 6rem !important;
  }

  .pt-lg-8 {
    padding-top: 8rem !important;
  }

  .pt-lg-9 {
    padding-top: 10rem !important;
  }

  .pt-lg-10 {
    padding-top: 12rem !important;
  }

  .pt-lg-11 {
    padding-top: 14rem !important;
  }

  .pt-lg-12 {
    padding-top: 16rem !important;
  }

  .pt-lg-13 {
    padding-top: 18rem !important;
  }

  .pt-lg-14 {
    padding-top: 20rem !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pr-lg-3 {
    padding-right: 1rem !important;
  }

  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pr-lg-5 {
    padding-right: 3rem !important;
  }

  .pr-lg-6 {
    padding-right: 4rem !important;
  }

  .pr-lg-7 {
    padding-right: 6rem !important;
  }

  .pr-lg-8 {
    padding-right: 8rem !important;
  }

  .pr-lg-9 {
    padding-right: 10rem !important;
  }

  .pr-lg-10 {
    padding-right: 12rem !important;
  }

  .pr-lg-11 {
    padding-right: 14rem !important;
  }

  .pr-lg-12 {
    padding-right: 16rem !important;
  }

  .pr-lg-13 {
    padding-right: 18rem !important;
  }

  .pr-lg-14 {
    padding-right: 20rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 12rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 14rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 16rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 18rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 20rem !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }

  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }

  .pl-lg-3 {
    padding-left: 1rem !important;
  }

  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }

  .pl-lg-5 {
    padding-left: 3rem !important;
  }

  .pl-lg-6 {
    padding-left: 4rem !important;
  }

  .pl-lg-7 {
    padding-left: 6rem !important;
  }

  .pl-lg-8 {
    padding-left: 8rem !important;
  }

  .pl-lg-9 {
    padding-left: 10rem !important;
  }

  .pl-lg-10 {
    padding-left: 12rem !important;
  }

  .pl-lg-11 {
    padding-left: 14rem !important;
  }

  .pl-lg-12 {
    padding-left: 16rem !important;
  }

  .pl-lg-13 {
    padding-left: 18rem !important;
  }

  .pl-lg-14 {
    padding-left: 20rem !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .w-lg-150 {
    width: 150px !important;
  }

  .w-lg-200 {
    width: 200px !important;
  }

  .w-lg-250 {
    width: 250px !important;
  }

  .w-lg-300 {
    width: 300px !important;
  }

  .w-lg-350 {
    width: 350px !important;
  }

  .w-lg-400 {
    width: 400px !important;
  }

  .w-lg-450 {
    width: 450px !important;
  }

  .w-lg-500 {
    width: 500px !important;
  }

  .w-lg-550 {
    width: 550px !important;
  }

  .w-lg-600 {
    width: 600px !important;
  }

  .w-lg-650 {
    width: 650px !important;
  }

  .w-lg-700 {
    width: 700px !important;
  }

  .h-lg-150 {
    height: 150px !important;
  }

  .h-lg-200 {
    height: 200px !important;
  }

  .h-lg-250 {
    height: 250px !important;
  }

  .h-lg-300 {
    height: 300px !important;
  }

  .h-lg-350 {
    height: 350px !important;
  }

  .h-lg-400 {
    height: 400px !important;
  }

  .h-lg-450 {
    height: 450px !important;
  }

  .h-lg-500 {
    height: 500px !important;
  }

  .h-lg-550 {
    height: 550px !important;
  }

  .h-lg-600 {
    height: 600px !important;
  }

  .h-lg-650 {
    height: 650px !important;
  }

  .h-lg-700 {
    height: 700px !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .h-xl-10 {
    height: 10% !important;
  }

  .h-xl-20 {
    height: 20% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-30 {
    height: 30% !important;
  }

  .h-xl-40 {
    height: 40% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-60 {
    height: 60% !important;
  }

  .h-xl-70 {
    height: 70% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-80 {
    height: 80% !important;
  }

  .h-xl-90 {
    height: 90% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 4rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .m-xl-8 {
    margin: 8rem !important;
  }

  .m-xl-9 {
    margin: 10rem !important;
  }

  .m-xl-10 {
    margin: 12rem !important;
  }

  .m-xl-11 {
    margin: 14rem !important;
  }

  .m-xl-12 {
    margin: 16rem !important;
  }

  .m-xl-13 {
    margin: 18rem !important;
  }

  .m-xl-14 {
    margin: 20rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xl-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-xl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-xl-13 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-xl-14 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xl-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xl-13 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xl-14 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 4rem !important;
  }

  .mt-xl-7 {
    margin-top: 6rem !important;
  }

  .mt-xl-8 {
    margin-top: 8rem !important;
  }

  .mt-xl-9 {
    margin-top: 10rem !important;
  }

  .mt-xl-10 {
    margin-top: 12rem !important;
  }

  .mt-xl-11 {
    margin-top: 14rem !important;
  }

  .mt-xl-12 {
    margin-top: 16rem !important;
  }

  .mt-xl-13 {
    margin-top: 18rem !important;
  }

  .mt-xl-14 {
    margin-top: 20rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mr-xl-3 {
    margin-right: 1rem !important;
  }

  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mr-xl-5 {
    margin-right: 3rem !important;
  }

  .mr-xl-6 {
    margin-right: 4rem !important;
  }

  .mr-xl-7 {
    margin-right: 6rem !important;
  }

  .mr-xl-8 {
    margin-right: 8rem !important;
  }

  .mr-xl-9 {
    margin-right: 10rem !important;
  }

  .mr-xl-10 {
    margin-right: 12rem !important;
  }

  .mr-xl-11 {
    margin-right: 14rem !important;
  }

  .mr-xl-12 {
    margin-right: 16rem !important;
  }

  .mr-xl-13 {
    margin-right: 18rem !important;
  }

  .mr-xl-14 {
    margin-right: 20rem !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 16rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 18rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 20rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ml-xl-3 {
    margin-left: 1rem !important;
  }

  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ml-xl-5 {
    margin-left: 3rem !important;
  }

  .ml-xl-6 {
    margin-left: 4rem !important;
  }

  .ml-xl-7 {
    margin-left: 6rem !important;
  }

  .ml-xl-8 {
    margin-left: 8rem !important;
  }

  .ml-xl-9 {
    margin-left: 10rem !important;
  }

  .ml-xl-10 {
    margin-left: 12rem !important;
  }

  .ml-xl-11 {
    margin-left: 14rem !important;
  }

  .ml-xl-12 {
    margin-left: 16rem !important;
  }

  .ml-xl-13 {
    margin-left: 18rem !important;
  }

  .ml-xl-14 {
    margin-left: 20rem !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .m-xl-n6 {
    margin: -4rem !important;
  }

  .m-xl-n7 {
    margin: -6rem !important;
  }

  .m-xl-n8 {
    margin: -8rem !important;
  }

  .m-xl-n9 {
    margin: -10rem !important;
  }

  .m-xl-n10 {
    margin: -12rem !important;
  }

  .m-xl-n11 {
    margin: -14rem !important;
  }

  .m-xl-n12 {
    margin: -16rem !important;
  }

  .m-xl-n13 {
    margin: -18rem !important;
  }

  .m-xl-n14 {
    margin: -20rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xl-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-xl-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-xl-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-xl-n13 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }

  .mx-xl-n14 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xl-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xl-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xl-n13 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-xl-n14 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xl-n6 {
    margin-top: -4rem !important;
  }

  .mt-xl-n7 {
    margin-top: -6rem !important;
  }

  .mt-xl-n8 {
    margin-top: -8rem !important;
  }

  .mt-xl-n9 {
    margin-top: -10rem !important;
  }

  .mt-xl-n10 {
    margin-top: -12rem !important;
  }

  .mt-xl-n11 {
    margin-top: -14rem !important;
  }

  .mt-xl-n12 {
    margin-top: -16rem !important;
  }

  .mt-xl-n13 {
    margin-top: -18rem !important;
  }

  .mt-xl-n14 {
    margin-top: -20rem !important;
  }

  .mr-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-xl-n3 {
    margin-right: -1rem !important;
  }

  .mr-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mr-xl-n5 {
    margin-right: -3rem !important;
  }

  .mr-xl-n6 {
    margin-right: -4rem !important;
  }

  .mr-xl-n7 {
    margin-right: -6rem !important;
  }

  .mr-xl-n8 {
    margin-right: -8rem !important;
  }

  .mr-xl-n9 {
    margin-right: -10rem !important;
  }

  .mr-xl-n10 {
    margin-right: -12rem !important;
  }

  .mr-xl-n11 {
    margin-right: -14rem !important;
  }

  .mr-xl-n12 {
    margin-right: -16rem !important;
  }

  .mr-xl-n13 {
    margin-right: -18rem !important;
  }

  .mr-xl-n14 {
    margin-right: -20rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -16rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -18rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -20rem !important;
  }

  .ml-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-xl-n3 {
    margin-left: -1rem !important;
  }

  .ml-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ml-xl-n5 {
    margin-left: -3rem !important;
  }

  .ml-xl-n6 {
    margin-left: -4rem !important;
  }

  .ml-xl-n7 {
    margin-left: -6rem !important;
  }

  .ml-xl-n8 {
    margin-left: -8rem !important;
  }

  .ml-xl-n9 {
    margin-left: -10rem !important;
  }

  .ml-xl-n10 {
    margin-left: -12rem !important;
  }

  .ml-xl-n11 {
    margin-left: -14rem !important;
  }

  .ml-xl-n12 {
    margin-left: -16rem !important;
  }

  .ml-xl-n13 {
    margin-left: -18rem !important;
  }

  .ml-xl-n14 {
    margin-left: -20rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 4rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .p-xl-8 {
    padding: 8rem !important;
  }

  .p-xl-9 {
    padding: 10rem !important;
  }

  .p-xl-10 {
    padding: 12rem !important;
  }

  .p-xl-11 {
    padding: 14rem !important;
  }

  .p-xl-12 {
    padding: 16rem !important;
  }

  .p-xl-13 {
    padding: 18rem !important;
  }

  .p-xl-14 {
    padding: 20rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xl-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xl-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-xl-13 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .px-xl-14 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xl-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xl-13 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-xl-14 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 4rem !important;
  }

  .pt-xl-7 {
    padding-top: 6rem !important;
  }

  .pt-xl-8 {
    padding-top: 8rem !important;
  }

  .pt-xl-9 {
    padding-top: 10rem !important;
  }

  .pt-xl-10 {
    padding-top: 12rem !important;
  }

  .pt-xl-11 {
    padding-top: 14rem !important;
  }

  .pt-xl-12 {
    padding-top: 16rem !important;
  }

  .pt-xl-13 {
    padding-top: 18rem !important;
  }

  .pt-xl-14 {
    padding-top: 20rem !important;
  }

  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pr-xl-3 {
    padding-right: 1rem !important;
  }

  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pr-xl-5 {
    padding-right: 3rem !important;
  }

  .pr-xl-6 {
    padding-right: 4rem !important;
  }

  .pr-xl-7 {
    padding-right: 6rem !important;
  }

  .pr-xl-8 {
    padding-right: 8rem !important;
  }

  .pr-xl-9 {
    padding-right: 10rem !important;
  }

  .pr-xl-10 {
    padding-right: 12rem !important;
  }

  .pr-xl-11 {
    padding-right: 14rem !important;
  }

  .pr-xl-12 {
    padding-right: 16rem !important;
  }

  .pr-xl-13 {
    padding-right: 18rem !important;
  }

  .pr-xl-14 {
    padding-right: 20rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 12rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 14rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 16rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 18rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 20rem !important;
  }

  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }

  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }

  .pl-xl-3 {
    padding-left: 1rem !important;
  }

  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }

  .pl-xl-5 {
    padding-left: 3rem !important;
  }

  .pl-xl-6 {
    padding-left: 4rem !important;
  }

  .pl-xl-7 {
    padding-left: 6rem !important;
  }

  .pl-xl-8 {
    padding-left: 8rem !important;
  }

  .pl-xl-9 {
    padding-left: 10rem !important;
  }

  .pl-xl-10 {
    padding-left: 12rem !important;
  }

  .pl-xl-11 {
    padding-left: 14rem !important;
  }

  .pl-xl-12 {
    padding-left: 16rem !important;
  }

  .pl-xl-13 {
    padding-left: 18rem !important;
  }

  .pl-xl-14 {
    padding-left: 20rem !important;
  }

  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .w-xl-150 {
    width: 150px !important;
  }

  .w-xl-200 {
    width: 200px !important;
  }

  .w-xl-250 {
    width: 250px !important;
  }

  .w-xl-300 {
    width: 300px !important;
  }

  .w-xl-350 {
    width: 350px !important;
  }

  .w-xl-400 {
    width: 400px !important;
  }

  .w-xl-450 {
    width: 450px !important;
  }

  .w-xl-500 {
    width: 500px !important;
  }

  .w-xl-550 {
    width: 550px !important;
  }

  .w-xl-600 {
    width: 600px !important;
  }

  .w-xl-650 {
    width: 650px !important;
  }

  .w-xl-700 {
    width: 700px !important;
  }

  .h-xl-150 {
    height: 150px !important;
  }

  .h-xl-200 {
    height: 200px !important;
  }

  .h-xl-250 {
    height: 250px !important;
  }

  .h-xl-300 {
    height: 300px !important;
  }

  .h-xl-350 {
    height: 350px !important;
  }

  .h-xl-400 {
    height: 400px !important;
  }

  .h-xl-450 {
    height: 450px !important;
  }

  .h-xl-500 {
    height: 500px !important;
  }

  .h-xl-550 {
    height: 550px !important;
  }

  .h-xl-600 {
    height: 600px !important;
  }

  .h-xl-650 {
    height: 650px !important;
  }

  .h-xl-700 {
    height: 700px !important;
  }
}

@media (min-width: 1500px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-20 {
    width: 20% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-70 {
    width: 70% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .h-xxl-10 {
    height: 10% !important;
  }

  .h-xxl-20 {
    height: 20% !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-30 {
    height: 30% !important;
  }

  .h-xxl-40 {
    height: 40% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-60 {
    height: 60% !important;
  }

  .h-xxl-70 {
    height: 70% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-80 {
    height: 80% !important;
  }

  .h-xxl-90 {
    height: 90% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 4rem !important;
  }

  .m-xxl-7 {
    margin: 6rem !important;
  }

  .m-xxl-8 {
    margin: 8rem !important;
  }

  .m-xxl-9 {
    margin: 10rem !important;
  }

  .m-xxl-10 {
    margin: 12rem !important;
  }

  .m-xxl-11 {
    margin: 14rem !important;
  }

  .m-xxl-12 {
    margin: 16rem !important;
  }

  .m-xxl-13 {
    margin: 18rem !important;
  }

  .m-xxl-14 {
    margin: 20rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xxl-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-xxl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-xxl-13 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }

  .mx-xxl-14 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xxl-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xxl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xxl-13 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xxl-14 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 4rem !important;
  }

  .mt-xxl-7 {
    margin-top: 6rem !important;
  }

  .mt-xxl-8 {
    margin-top: 8rem !important;
  }

  .mt-xxl-9 {
    margin-top: 10rem !important;
  }

  .mt-xxl-10 {
    margin-top: 12rem !important;
  }

  .mt-xxl-11 {
    margin-top: 14rem !important;
  }

  .mt-xxl-12 {
    margin-top: 16rem !important;
  }

  .mt-xxl-13 {
    margin-top: 18rem !important;
  }

  .mt-xxl-14 {
    margin-top: 20rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-0 {
    margin-right: 0 !important;
  }

  .mr-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mr-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mr-xxl-3 {
    margin-right: 1rem !important;
  }

  .mr-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mr-xxl-5 {
    margin-right: 3rem !important;
  }

  .mr-xxl-6 {
    margin-right: 4rem !important;
  }

  .mr-xxl-7 {
    margin-right: 6rem !important;
  }

  .mr-xxl-8 {
    margin-right: 8rem !important;
  }

  .mr-xxl-9 {
    margin-right: 10rem !important;
  }

  .mr-xxl-10 {
    margin-right: 12rem !important;
  }

  .mr-xxl-11 {
    margin-right: 14rem !important;
  }

  .mr-xxl-12 {
    margin-right: 16rem !important;
  }

  .mr-xxl-13 {
    margin-right: 18rem !important;
  }

  .mr-xxl-14 {
    margin-right: 20rem !important;
  }

  .mr-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 16rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 18rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 20rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-0 {
    margin-left: 0 !important;
  }

  .ml-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ml-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ml-xxl-3 {
    margin-left: 1rem !important;
  }

  .ml-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ml-xxl-5 {
    margin-left: 3rem !important;
  }

  .ml-xxl-6 {
    margin-left: 4rem !important;
  }

  .ml-xxl-7 {
    margin-left: 6rem !important;
  }

  .ml-xxl-8 {
    margin-left: 8rem !important;
  }

  .ml-xxl-9 {
    margin-left: 10rem !important;
  }

  .ml-xxl-10 {
    margin-left: 12rem !important;
  }

  .ml-xxl-11 {
    margin-left: 14rem !important;
  }

  .ml-xxl-12 {
    margin-left: 16rem !important;
  }

  .ml-xxl-13 {
    margin-left: 18rem !important;
  }

  .ml-xxl-14 {
    margin-left: 20rem !important;
  }

  .ml-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .m-xxl-n6 {
    margin: -4rem !important;
  }

  .m-xxl-n7 {
    margin: -6rem !important;
  }

  .m-xxl-n8 {
    margin: -8rem !important;
  }

  .m-xxl-n9 {
    margin: -10rem !important;
  }

  .m-xxl-n10 {
    margin: -12rem !important;
  }

  .m-xxl-n11 {
    margin: -14rem !important;
  }

  .m-xxl-n12 {
    margin: -16rem !important;
  }

  .m-xxl-n13 {
    margin: -18rem !important;
  }

  .m-xxl-n14 {
    margin: -20rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xxl-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xxl-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xxl-n13 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-xxl-n14 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -12rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -14rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -16rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -18rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -20rem !important;
  }

  .mr-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mr-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mr-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mr-xxl-n6 {
    margin-right: -4rem !important;
  }

  .mr-xxl-n7 {
    margin-right: -6rem !important;
  }

  .mr-xxl-n8 {
    margin-right: -8rem !important;
  }

  .mr-xxl-n9 {
    margin-right: -10rem !important;
  }

  .mr-xxl-n10 {
    margin-right: -12rem !important;
  }

  .mr-xxl-n11 {
    margin-right: -14rem !important;
  }

  .mr-xxl-n12 {
    margin-right: -16rem !important;
  }

  .mr-xxl-n13 {
    margin-right: -18rem !important;
  }

  .mr-xxl-n14 {
    margin-right: -20rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -16rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -18rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -20rem !important;
  }

  .ml-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ml-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ml-xxl-n5 {
    margin-left: -3rem !important;
  }

  .ml-xxl-n6 {
    margin-left: -4rem !important;
  }

  .ml-xxl-n7 {
    margin-left: -6rem !important;
  }

  .ml-xxl-n8 {
    margin-left: -8rem !important;
  }

  .ml-xxl-n9 {
    margin-left: -10rem !important;
  }

  .ml-xxl-n10 {
    margin-left: -12rem !important;
  }

  .ml-xxl-n11 {
    margin-left: -14rem !important;
  }

  .ml-xxl-n12 {
    margin-left: -16rem !important;
  }

  .ml-xxl-n13 {
    margin-left: -18rem !important;
  }

  .ml-xxl-n14 {
    margin-left: -20rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 4rem !important;
  }

  .p-xxl-7 {
    padding: 6rem !important;
  }

  .p-xxl-8 {
    padding: 8rem !important;
  }

  .p-xxl-9 {
    padding: 10rem !important;
  }

  .p-xxl-10 {
    padding: 12rem !important;
  }

  .p-xxl-11 {
    padding: 14rem !important;
  }

  .p-xxl-12 {
    padding: 16rem !important;
  }

  .p-xxl-13 {
    padding: 18rem !important;
  }

  .p-xxl-14 {
    padding: 20rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xxl-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xxl-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xxl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-xxl-13 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }

  .px-xxl-14 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xxl-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xxl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xxl-13 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-xxl-14 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4rem !important;
  }

  .pt-xxl-7 {
    padding-top: 6rem !important;
  }

  .pt-xxl-8 {
    padding-top: 8rem !important;
  }

  .pt-xxl-9 {
    padding-top: 10rem !important;
  }

  .pt-xxl-10 {
    padding-top: 12rem !important;
  }

  .pt-xxl-11 {
    padding-top: 14rem !important;
  }

  .pt-xxl-12 {
    padding-top: 16rem !important;
  }

  .pt-xxl-13 {
    padding-top: 18rem !important;
  }

  .pt-xxl-14 {
    padding-top: 20rem !important;
  }

  .pr-xxl-0 {
    padding-right: 0 !important;
  }

  .pr-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pr-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pr-xxl-3 {
    padding-right: 1rem !important;
  }

  .pr-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pr-xxl-5 {
    padding-right: 3rem !important;
  }

  .pr-xxl-6 {
    padding-right: 4rem !important;
  }

  .pr-xxl-7 {
    padding-right: 6rem !important;
  }

  .pr-xxl-8 {
    padding-right: 8rem !important;
  }

  .pr-xxl-9 {
    padding-right: 10rem !important;
  }

  .pr-xxl-10 {
    padding-right: 12rem !important;
  }

  .pr-xxl-11 {
    padding-right: 14rem !important;
  }

  .pr-xxl-12 {
    padding-right: 16rem !important;
  }

  .pr-xxl-13 {
    padding-right: 18rem !important;
  }

  .pr-xxl-14 {
    padding-right: 20rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 12rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 14rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 16rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 18rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 20rem !important;
  }

  .pl-xxl-0 {
    padding-left: 0 !important;
  }

  .pl-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .pl-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .pl-xxl-3 {
    padding-left: 1rem !important;
  }

  .pl-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .pl-xxl-5 {
    padding-left: 3rem !important;
  }

  .pl-xxl-6 {
    padding-left: 4rem !important;
  }

  .pl-xxl-7 {
    padding-left: 6rem !important;
  }

  .pl-xxl-8 {
    padding-left: 8rem !important;
  }

  .pl-xxl-9 {
    padding-left: 10rem !important;
  }

  .pl-xxl-10 {
    padding-left: 12rem !important;
  }

  .pl-xxl-11 {
    padding-left: 14rem !important;
  }

  .pl-xxl-12 {
    padding-left: 16rem !important;
  }

  .pl-xxl-13 {
    padding-left: 18rem !important;
  }

  .pl-xxl-14 {
    padding-left: 20rem !important;
  }

  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .w-xxl-150 {
    width: 150px !important;
  }

  .w-xxl-200 {
    width: 200px !important;
  }

  .w-xxl-250 {
    width: 250px !important;
  }

  .w-xxl-300 {
    width: 300px !important;
  }

  .w-xxl-350 {
    width: 350px !important;
  }

  .w-xxl-400 {
    width: 400px !important;
  }

  .w-xxl-450 {
    width: 450px !important;
  }

  .w-xxl-500 {
    width: 500px !important;
  }

  .w-xxl-550 {
    width: 550px !important;
  }

  .w-xxl-600 {
    width: 600px !important;
  }

  .w-xxl-650 {
    width: 650px !important;
  }

  .w-xxl-700 {
    width: 700px !important;
  }

  .h-xxl-150 {
    height: 150px !important;
  }

  .h-xxl-200 {
    height: 200px !important;
  }

  .h-xxl-250 {
    height: 250px !important;
  }

  .h-xxl-300 {
    height: 300px !important;
  }

  .h-xxl-350 {
    height: 350px !important;
  }

  .h-xxl-400 {
    height: 400px !important;
  }

  .h-xxl-450 {
    height: 450px !important;
  }

  .h-xxl-500 {
    height: 500px !important;
  }

  .h-xxl-550 {
    height: 550px !important;
  }

  .h-xxl-600 {
    height: 600px !important;
  }

  .h-xxl-650 {
    height: 650px !important;
  }

  .h-xxl-700 {
    height: 700px !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

@font-face {
  font-family: SpartanMB;
  src: url(../fonts/SpartanMB-Medium.eot);
  src: url(../fonts/SpartanMB-Medium.eot?#iefix) format("embedded-opentype"),
    url(../fonts/SpartanMB-Medium.svg#SpartanMB-Medium) format("svg"),
    url(../fonts/SpartanMB-Medium.ttf) format("truetype"),
    url(../fonts/SpartanMB-Medium.woff) format("woff"),
    url(../fonts/SpartanMB-Medium.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SpartanMB;
  src: url(../fonts/SpartanMB-SemiBold.eot);
  src: url(../fonts/SpartanMB-SemiBold.eot?#iefix) format("embedded-opentype"),
    url(../fonts/SpartanMB-SemiBold.svg#SpartanMB-SemiBold) format("svg"),
    url(../fonts/SpartanMB-SemiBold.ttf) format("truetype"),
    url(../fonts/SpartanMB-SemiBold.woff) format("woff"),
    url(../fonts/SpartanMB-SemiBold.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SpartanMB;
  src: url(../fonts/SpartanMB-Bold.eot);
  src: url(../fonts/SpartanMB-Bold.eot?#iefix) format("embedded-opentype"),
    url(../fonts/SpartanMB-Bold.svg#SpartanMB-Bold) format("svg"),
    url(../fonts/SpartanMB-Bold.ttf) format("truetype"),
    url(../fonts/SpartanMB-Bold.woff) format("woff"),
    url(../fonts/SpartanMB-Bold.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
}

body {
  overflow-x: hidden;
}

body.rtl {
  direction: rtl;
}

.animation-delay-1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.animation-delay-2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.animation-delay-3 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.animation-delay-4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.animation-delay-5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animation-delay-6 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.animation-delay-7 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animation-delay-8 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.animation-delay-9 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.animation-delay-10 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes fxFadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fxFadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fx-fade-up {
  -webkit-animation-name: fxFadeUp;
  animation-name: fxFadeUp;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: cubic-bezier(0.2, 0.6, 0.3, 1);
  animation-timing-function: cubic-bezier(0.2, 0.6, 0.3, 1);
}

@-webkit-keyframes fxFadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fxFadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fx-fade-down {
  -webkit-animation-name: fxFadeDown;
  animation-name: fxFadeDown;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes fxFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fxFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fx-fade-in {
  -webkit-animation-name: fxFadeIn;
  animation-name: fxFadeIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-pastel-primary {
  background-color: #e4f0ff !important;
}

.bg-pastel-secondary {
  background-color: #ebedf2 !important;
}

.bg-pastel-success {
  background-color: #e7f7f2 !important;
}

.bg-pastel-info {
  background-color: #e3f6ff !important;
}

.bg-pastel-warning {
  background-color: #fff8e5 !important;
}

.bg-pastel-danger {
  background-color: #f7e8e8 !important;
}

.bg-pastel-light {
  background-color: #fefeff !important;
}

.bg-pastel-dark {
  background-color: #e4e6ea !important;
}

.bg-pastel-purple {
  background-color: #edeefc !important;
}

.bg-pastel-sky {
  background-color: #fcfeff !important;
}

.bg-pastel-pistachio {
  background-color: #fbfefe !important;
}

.bg-pastel-darkblue {
  background-color: #e4e6ee !important;
}

.bg-gradient-light {
  background: linear-gradient(to top, #f6f9fc 0, #fff 100%);
}

.bg-gradient-light-top {
  background: linear-gradient(to bottom, #f6f9fc 0, #fff 100%);
}

.bg-gradient-sky {
  background: linear-gradient(to top, #e9f4ff 0, #fff 100%);
}

.bg-gradient-sky-top {
  background: linear-gradient(to bottom, #e9f4ff 0, #fff 100%);
}

.bg-gray-100 {
  background: #f8f9fa !important;
}

.bg-gray-200 {
  background: #e9ecef !important;
}

.bg-gray-300 {
  background: #dee2e6 !important;
}

.bg-gray-400 {
  background: #ced4da !important;
}

.bg-gray-500 {
  background: #adb5bd !important;
}

.bg-gray-600 {
  background: #6c757d !important;
}

.bg-gray-700 {
  background: #495057 !important;
}

.bg-gray-800 {
  background: #343a40 !important;
}

.bg-gray-900 {
  background: #212529 !important;
}

.bg-topography-light {
  background-image: url(../img/svg/topography-light.svg);
}

.bg-topography-dark {
  background-image: url(../img/svg/topography-dark.svg);
}

.bg-primary-hover {
  transition: background-color 0.4s linear;
}

.bg-primary-hover:hover {
  background-color: #0170f9 !important;
}

.bg-pastel-primary-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-primary-hover:hover {
  background-color: #c0dcff !important;
}

.bg-secondary-hover {
  transition: background-color 0.4s linear;
}

.bg-secondary-hover:hover {
  background-color: #4c5c7a !important;
}

.bg-pastel-secondary-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-secondary-hover:hover {
  background-color: #d5dae4 !important;
}

.bg-success-hover {
  transition: background-color 0.4s linear;
}

.bg-success-hover:hover {
  background-color: #2ca17c !important;
}

.bg-pastel-success-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-success-hover:hover {
  background-color: #cceee3 !important;
}

.bg-info-hover {
  transition: background-color 0.4s linear;
}

.bg-info-hover:hover {
  background-color: #00a7f3 !important;
}

.bg-pastel-info-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-info-hover:hover {
  background-color: #bfebff !important;
}

.bg-warning-hover {
  transition: background-color 0.4s linear;
}

.bg-warning-hover:hover {
  background-color: #fbbd02 !important;
}

.bg-pastel-warning-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-warning-hover:hover {
  background-color: #ffeec1 !important;
}

.bg-danger-hover {
  transition: background-color 0.4s linear;
}

.bg-danger-hover:hover {
  background-color: #a33939 !important;
}

.bg-pastel-danger-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-danger-hover:hover {
  background-color: #eecece !important;
}

.bg-light-hover {
  transition: background-color 0.4s linear;
}

.bg-light-hover:hover {
  background-color: #dbe7f3 !important;
}

.bg-pastel-light-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-light-hover:hover {
  background-color: #dadaff !important;
}

.bg-dark-hover {
  transition: background-color 0.4s linear;
}

.bg-dark-hover:hover {
  background-color: #141f36 !important;
}

.bg-pastel-dark-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-dark-hover:hover {
  background-color: #d0d3da !important;
}

.bg-purple-hover {
  transition: background-color 0.4s linear;
}

.bg-purple-hover:hover {
  background-color: #4755e3 !important;
}

.bg-pastel-purple-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-purple-hover:hover {
  background-color: #ced1f7 !important;
}

.bg-sky-hover {
  transition: background-color 0.4s linear;
}

.bg-sky-hover:hover {
  background-color: #c5e2ff !important;
}

.bg-pastel-sky-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-sky-hover:hover {
  background-color: #d8f2ff !important;
}

.bg-pistachio-hover {
  transition: background-color 0.4s linear;
}

.bg-pistachio-hover:hover {
  background-color: #c1edeb !important;
}

.bg-pastel-pistachio-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-pistachio-hover:hover {
  background-color: #def7f7 !important;
}

.bg-darkblue-hover {
  transition: background-color 0.4s linear;
}

.bg-darkblue-hover:hover {
  background-color: #172157 !important;
}

.bg-pastel-darkblue-hover {
  transition: background-color 0.4s linear;
}

.bg-pastel-darkblue-hover:hover {
  background-color: #ced2e0 !important;
}

.hover-scale {
  transition: transform 0.25s ease, box-shadow 0.25s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-scale {
    transition: none;
  }
}

.hover-scale:hover {
  transform: scale3d(1.09, 1.09, 1.09);
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.275) !important;
}

.hover-lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease, color 0.25s ease,
    background-color 0.15s ease-in;
}

@media (prefers-reduced-motion: reduce) {
  .hover-lift {
    transition: none;
  }
}

.hover-lift i.hover-lift-icon {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-lift i.hover-lift-icon {
    transition: none;
  }
}

.hover-lift:hover {
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.275) !important;
  transform: translate3d(0, -5px, 0);
}

.hover-lift:hover i.hover-lift-icon {
  margin-left: 15px !important;
}

.hover-lift-light {
  transition: box-shadow 0.25s ease, transform 0.25s ease, color 0.25s ease,
    background-color 0.15s ease-in;
}

@media (prefers-reduced-motion: reduce) {
  .hover-lift-light {
    transition: none;
  }
}

.hover-lift-light i.hover-lift-icon {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-lift-light i.hover-lift-icon {
    transition: none;
  }
}

.hover-lift-light:hover {
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.05) !important;
  transform: translate3d(0, -5px, 0);
}

.hover-lift-light:hover i.hover-lift-icon {
  margin-left: 15px !important;
}

body.rtl .hover-lift-right:hover i.hover-lift-icon,
body.rtl .hover-lift:hover i.hover-lift-icon {
  margin-left: initial !important;
  margin-right: 15px !important;
}

.hover-mask {
  overflow: hidden;
  display: block;
  position: relative;
}

.hover-mask:hover .hover-mask-content {
  opacity: 1;
}

.hover-mask:hover .hover-mask-content .hover-mask-content-down,
.hover-mask:hover .hover-mask-content .hover-mask-content-up {
  transform: none;
}

.hover-mask:hover .hover-mask-image:not(.jarallax) {
  transform: scale3d(1.15, 1.15, 1.15);
}

.hover-mask-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  height: 100%;
  transition: transform 0.5s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-mask-image {
    transition: none;
  }
}

.hover-mask-content {
  display: block;
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 9;
  transition: all 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-mask-content {
    transition: none;
  }
}

.hover-mask-content-up {
  transform: translateY(-15px);
  transition: transform 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-mask-content-up {
    transition: none;
  }
}

.hover-mask-content-down {
  transform: translateY(15px);
  transition: transform 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .hover-mask-content-down {
    transition: none;
  }
}

.icon-circle[class*="text-"] [fill]:not([fill="none"]),
.icon-circle[class*="text-"] svg:not([fill="none"]),
.svg-icon[class*="text-"] [fill]:not([fill="none"]),
.svg-icon[class*="text-"] svg:not([fill="none"]) {
  fill: currentColor !important;
}

.icon-circle[class*="text-"] svg [stroke]:not([stroke="none"]),
.icon-circle[class*="text-"] svg [style*="stroke"],
.svg-icon[class*="text-"] svg [stroke]:not([stroke="none"]),
.svg-icon[class*="text-"] svg [style*="stroke"] {
  stroke: currentColor !important;
}

.svg-icon>svg {
  width: 1.5rem;
  height: 1.5rem;
}

.svg-icon-xxl>svg {
  width: 4rem;
  height: 4rem;
}

.svg-icon-xl>svg {
  width: 3.25rem;
  height: 3.25rem;
}

.svg-icon-lg>svg {
  width: 2.5rem;
  height: 2.5rem;
}

.svg-icon-sm>svg {
  width: 1rem;
  height: 1rem;
}

.svg-icon-xs>svg {
  width: 0.8rem;
  height: 0.8rem;
}

.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
}

a.icon-circle:focus,
a.icon-circle:hover {
  text-decoration: none;
}

.icon-circle>svg {
  width: 1.35rem;
  height: 1.35rem;
}

.icon-circle-xl {
  width: 5rem;
  height: 5rem;
}

.icon-circle-xl>svg {
  width: 2.75rem;
  height: 2.75rem;
}

.icon-circle-lg {
  width: 4rem;
  height: 4rem;
}

.icon-circle-lg>svg {
  width: 2rem;
  height: 2rem;
}

.icon-circle-sm {
  width: 1.7rem;
  height: 1.7rem;
}

.icon-circle-sm>svg {
  width: 1rem;
  height: 1rem;
}

.icon-circle-xs {
  width: 1.2rem;
  height: 1.2rem;
}

.icon-circle-xs>svg {
  width: 0.75rem;
  height: 0.75rem;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

.overlay-primary:before {
  background: #2083fe !important;
}

.overlay-secondary:before {
  background: #5a6c90 !important;
}

.overlay-success:before {
  background: #34bd91 !important;
}

.overlay-info:before {
  background: #18b7ff !important;
}

.overlay-warning:before {
  background: #fdc724 !important;
}

.overlay-danger:before {
  background: #bd4242 !important;
}

.overlay-light:before {
  background: #f6f9fc !important;
}

.overlay-dark:before {
  background: #1e2e50 !important;
}

.overlay-purple:before {
  background: #6672e8 !important;
}

.overlay-sky:before {
  background: #e9f4ff !important;
}

.overlay-pistachio:before {
  background: #ddf5f4 !important;
}

.overlay-darkblue:before {
  background: #1f2c73 !important;
}

.overlay-white:before {
  background: #fff !important;
}

.overlay-gradient-dark-bottom:before {
  background: linear-gradient(rgba(255, 255, 255, 0) 60%,
      rgba(29, 34, 45, 0.375) 100%);
}

.overlay-gradient-white-to-dark:before {
  background: linear-gradient(-180deg,
      #fff 0,
      rgba(255, 255, 255, 0.6) 25%,
      rgba(255, 255, 255, 0) 100%),
    linear-gradient(-180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.45) 100%);
}

.overlay-10:before {
  opacity: 0.1;
}

.overlay-20:before {
  opacity: 0.2;
}

.overlay-25:before {
  opacity: 0.25;
}

.overlay-30:before {
  opacity: 0.3;
}

.overlay-40:before {
  opacity: 0.4;
}

.overlay-50:before {
  opacity: 0.5;
}

.overlay-60:before {
  opacity: 0.6;
}

.overlay-70:before {
  opacity: 0.7;
}

.overlay-75:before {
  opacity: 0.75;
}

.overlay-80:before {
  opacity: 0.8;
}

.overlay-90:before {
  opacity: 0.9;
}

.overlay-100:before {
  opacity: 1;
}

.center-x {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.center-y {
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.center-xy {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@media (min-width: 576px) {
  .center-x-sm {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .center-y-sm {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .center-xy-sm {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (min-width: 768px) {
  .center-x-md {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .center-y-md {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .center-xy-md {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (min-width: 992px) {
  .center-x-lg {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .center-y-lg {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .center-xy-lg {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (min-width: 1200px) {
  .center-x-xl {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .center-y-xl {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .center-xy-xl {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (min-width: 1500px) {
  .center-x-xxl {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .center-y-xxl {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .center-xy-xxl {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.relative-top-1 {
  position: relative;
  top: 1px;
}

.relative-top--1 {
  position: relative;
  top: -1px;
}

.relative-top-2 {
  position: relative;
  top: 2px;
}

.relative-top--2 {
  position: relative;
  top: -2px;
}

.relative-top-3 {
  position: relative;
  top: 3px;
}

.relative-top--3 {
  position: relative;
  top: -3px;
}

.relative-top-4 {
  position: relative;
  top: 4px;
}

.relative-top--4 {
  position: relative;
  top: -4px;
}

.relative-top-5 {
  position: relative;
  top: 5px;
}

.relative-top--5 {
  position: relative;
  top: -5px;
}

.relative-top-6 {
  position: relative;
  top: 6px;
}

.relative-top--6 {
  position: relative;
  top: -6px;
}

.relative-top-7 {
  position: relative;
  top: 7px;
}

.relative-top--7 {
  position: relative;
  top: -7px;
}

.relative-top-8 {
  position: relative;
  top: 8px;
}

.relative-top--8 {
  position: relative;
  top: -8px;
}

.relative-top-9 {
  position: relative;
  top: 9px;
}

.relative-top--9 {
  position: relative;
  top: -9px;
}

.relative-top-10 {
  position: relative;
  top: 10px;
}

.relative-top--10 {
  position: relative;
  top: -10px;
}

.vw-10 {
  width: 10vw !important;
}

.vh-10 {
  height: 10vh !important;
}

.min-vw-10 {
  min-width: 10vw !important;
}

.min-vh-10 {
  min-height: 10vh !important;
}

.vw-20 {
  width: 20vw !important;
}

.vh-20 {
  height: 20vh !important;
}

.min-vw-20 {
  min-width: 20vw !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.vw-25 {
  width: 25vw !important;
}

.vh-25 {
  height: 25vh !important;
}

.min-vw-25 {
  min-width: 25vw !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.vw-30 {
  width: 30vw !important;
}

.vh-30 {
  height: 30vh !important;
}

.min-vw-30 {
  min-width: 30vw !important;
}

.min-vh-30 {
  min-height: 30vh !important;
}

.vw-40 {
  width: 40vw !important;
}

.vh-40 {
  height: 40vh !important;
}

.min-vw-40 {
  min-width: 40vw !important;
}

.min-vh-40 {
  min-height: 40vh !important;
}

.vw-50 {
  width: 50vw !important;
}

.vh-50 {
  height: 50vh !important;
}

.min-vw-50 {
  min-width: 50vw !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.vw-60 {
  width: 60vw !important;
}

.vh-60 {
  height: 60vh !important;
}

.min-vw-60 {
  min-width: 60vw !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.vw-70 {
  width: 70vw !important;
}

.vh-70 {
  height: 70vh !important;
}

.min-vw-70 {
  min-width: 70vw !important;
}

.min-vh-70 {
  min-height: 70vh !important;
}

.vw-75 {
  width: 75vw !important;
}

.vh-75 {
  height: 75vh !important;
}

.min-vw-75 {
  min-width: 75vw !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.vw-80 {
  width: 80vw !important;
}

.vh-80 {
  height: 80vh !important;
}

.min-vw-80 {
  min-width: 80vw !important;
}

.min-vh-80 {
  min-height: 80vh !important;
}

.vw-90 {
  width: 90vw !important;
}

.vh-90 {
  height: 90vh !important;
}

.min-vw-90 {
  min-width: 90vw !important;
}

.min-vh-90 {
  min-height: 90vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

@media (min-width: 576px) {
  .vw-sm-10 {
    width: 10vw !important;
  }

  .vh-sm-10 {
    height: 10vh !important;
  }

  .min-vw-sm-10 {
    min-width: 10vw !important;
  }

  .min-vh-sm-10 {
    min-height: 10vh !important;
  }

  .vw-sm-20 {
    width: 20vw !important;
  }

  .vh-sm-20 {
    height: 20vh !important;
  }

  .min-vw-sm-20 {
    min-width: 20vw !important;
  }

  .min-vh-sm-20 {
    min-height: 20vh !important;
  }

  .vw-sm-25 {
    width: 25vw !important;
  }

  .vh-sm-25 {
    height: 25vh !important;
  }

  .min-vw-sm-25 {
    min-width: 25vw !important;
  }

  .min-vh-sm-25 {
    min-height: 25vh !important;
  }

  .vw-sm-30 {
    width: 30vw !important;
  }

  .vh-sm-30 {
    height: 30vh !important;
  }

  .min-vw-sm-30 {
    min-width: 30vw !important;
  }

  .min-vh-sm-30 {
    min-height: 30vh !important;
  }

  .vw-sm-40 {
    width: 40vw !important;
  }

  .vh-sm-40 {
    height: 40vh !important;
  }

  .min-vw-sm-40 {
    min-width: 40vw !important;
  }

  .min-vh-sm-40 {
    min-height: 40vh !important;
  }

  .vw-sm-50 {
    width: 50vw !important;
  }

  .vh-sm-50 {
    height: 50vh !important;
  }

  .min-vw-sm-50 {
    min-width: 50vw !important;
  }

  .min-vh-sm-50 {
    min-height: 50vh !important;
  }

  .vw-sm-60 {
    width: 60vw !important;
  }

  .vh-sm-60 {
    height: 60vh !important;
  }

  .min-vw-sm-60 {
    min-width: 60vw !important;
  }

  .min-vh-sm-60 {
    min-height: 60vh !important;
  }

  .vw-sm-70 {
    width: 70vw !important;
  }

  .vh-sm-70 {
    height: 70vh !important;
  }

  .min-vw-sm-70 {
    min-width: 70vw !important;
  }

  .min-vh-sm-70 {
    min-height: 70vh !important;
  }

  .vw-sm-75 {
    width: 75vw !important;
  }

  .vh-sm-75 {
    height: 75vh !important;
  }

  .min-vw-sm-75 {
    min-width: 75vw !important;
  }

  .min-vh-sm-75 {
    min-height: 75vh !important;
  }

  .vw-sm-80 {
    width: 80vw !important;
  }

  .vh-sm-80 {
    height: 80vh !important;
  }

  .min-vw-sm-80 {
    min-width: 80vw !important;
  }

  .min-vh-sm-80 {
    min-height: 80vh !important;
  }

  .vw-sm-90 {
    width: 90vw !important;
  }

  .vh-sm-90 {
    height: 90vh !important;
  }

  .min-vw-sm-90 {
    min-width: 90vw !important;
  }

  .min-vh-sm-90 {
    min-height: 90vh !important;
  }

  .vw-sm-100 {
    width: 100vw !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .min-vw-sm-100 {
    min-width: 100vw !important;
  }

  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
}

@media (min-width: 768px) {
  .vw-md-10 {
    width: 10vw !important;
  }

  .vh-md-10 {
    height: 10vh !important;
  }

  .min-vw-md-10 {
    min-width: 10vw !important;
  }

  .min-vh-md-10 {
    min-height: 10vh !important;
  }

  .vw-md-20 {
    width: 20vw !important;
  }

  .vh-md-20 {
    height: 20vh !important;
  }

  .min-vw-md-20 {
    min-width: 20vw !important;
  }

  .min-vh-md-20 {
    min-height: 20vh !important;
  }

  .vw-md-25 {
    width: 25vw !important;
  }

  .vh-md-25 {
    height: 25vh !important;
  }

  .min-vw-md-25 {
    min-width: 25vw !important;
  }

  .min-vh-md-25 {
    min-height: 25vh !important;
  }

  .vw-md-30 {
    width: 30vw !important;
  }

  .vh-md-30 {
    height: 30vh !important;
  }

  .min-vw-md-30 {
    min-width: 30vw !important;
  }

  .min-vh-md-30 {
    min-height: 30vh !important;
  }

  .vw-md-40 {
    width: 40vw !important;
  }

  .vh-md-40 {
    height: 40vh !important;
  }

  .min-vw-md-40 {
    min-width: 40vw !important;
  }

  .min-vh-md-40 {
    min-height: 40vh !important;
  }

  .vw-md-50 {
    width: 50vw !important;
  }

  .vh-md-50 {
    height: 50vh !important;
  }

  .min-vw-md-50 {
    min-width: 50vw !important;
  }

  .min-vh-md-50 {
    min-height: 50vh !important;
  }

  .vw-md-60 {
    width: 60vw !important;
  }

  .vh-md-60 {
    height: 60vh !important;
  }

  .min-vw-md-60 {
    min-width: 60vw !important;
  }

  .min-vh-md-60 {
    min-height: 60vh !important;
  }

  .vw-md-70 {
    width: 70vw !important;
  }

  .vh-md-70 {
    height: 70vh !important;
  }

  .min-vw-md-70 {
    min-width: 70vw !important;
  }

  .min-vh-md-70 {
    min-height: 70vh !important;
  }

  .vw-md-75 {
    width: 75vw !important;
  }

  .vh-md-75 {
    height: 75vh !important;
  }

  .min-vw-md-75 {
    min-width: 75vw !important;
  }

  .min-vh-md-75 {
    min-height: 75vh !important;
  }

  .vw-md-80 {
    width: 80vw !important;
  }

  .vh-md-80 {
    height: 80vh !important;
  }

  .min-vw-md-80 {
    min-width: 80vw !important;
  }

  .min-vh-md-80 {
    min-height: 80vh !important;
  }

  .vw-md-90 {
    width: 90vw !important;
  }

  .vh-md-90 {
    height: 90vh !important;
  }

  .min-vw-md-90 {
    min-width: 90vw !important;
  }

  .min-vh-md-90 {
    min-height: 90vh !important;
  }

  .vw-md-100 {
    width: 100vw !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .min-vw-md-100 {
    min-width: 100vw !important;
  }

  .min-vh-md-100 {
    min-height: 100vh !important;
  }
}

@media (min-width: 992px) {
  .vw-lg-10 {
    width: 10vw !important;
  }

  .vh-lg-10 {
    height: 10vh !important;
  }

  .min-vw-lg-10 {
    min-width: 10vw !important;
  }

  .min-vh-lg-10 {
    min-height: 10vh !important;
  }

  .vw-lg-20 {
    width: 20vw !important;
  }

  .vh-lg-20 {
    height: 20vh !important;
  }

  .min-vw-lg-20 {
    min-width: 20vw !important;
  }

  .min-vh-lg-20 {
    min-height: 20vh !important;
  }

  .vw-lg-25 {
    width: 25vw !important;
  }

  .vh-lg-25 {
    height: 25vh !important;
  }

  .min-vw-lg-25 {
    min-width: 25vw !important;
  }

  .min-vh-lg-25 {
    min-height: 25vh !important;
  }

  .vw-lg-30 {
    width: 30vw !important;
  }

  .vh-lg-30 {
    height: 30vh !important;
  }

  .min-vw-lg-30 {
    min-width: 30vw !important;
  }

  .min-vh-lg-30 {
    min-height: 30vh !important;
  }

  .vw-lg-40 {
    width: 40vw !important;
  }

  .vh-lg-40 {
    height: 40vh !important;
  }

  .min-vw-lg-40 {
    min-width: 40vw !important;
  }

  .min-vh-lg-40 {
    min-height: 40vh !important;
  }

  .vw-lg-50 {
    width: 50vw !important;
  }

  .vh-lg-50 {
    height: 50vh !important;
  }

  .min-vw-lg-50 {
    min-width: 50vw !important;
  }

  .min-vh-lg-50 {
    min-height: 50vh !important;
  }

  .vw-lg-60 {
    width: 60vw !important;
  }

  .vh-lg-60 {
    height: 60vh !important;
  }

  .min-vw-lg-60 {
    min-width: 60vw !important;
  }

  .min-vh-lg-60 {
    min-height: 60vh !important;
  }

  .vw-lg-70 {
    width: 70vw !important;
  }

  .vh-lg-70 {
    height: 70vh !important;
  }

  .min-vw-lg-70 {
    min-width: 70vw !important;
  }

  .min-vh-lg-70 {
    min-height: 70vh !important;
  }

  .vw-lg-75 {
    width: 75vw !important;
  }

  .vh-lg-75 {
    height: 75vh !important;
  }

  .min-vw-lg-75 {
    min-width: 75vw !important;
  }

  .min-vh-lg-75 {
    min-height: 75vh !important;
  }

  .vw-lg-80 {
    width: 80vw !important;
  }

  .vh-lg-80 {
    height: 80vh !important;
  }

  .min-vw-lg-80 {
    min-width: 80vw !important;
  }

  .min-vh-lg-80 {
    min-height: 80vh !important;
  }

  .vw-lg-90 {
    width: 90vw !important;
  }

  .vh-lg-90 {
    height: 90vh !important;
  }

  .min-vw-lg-90 {
    min-width: 90vw !important;
  }

  .min-vh-lg-90 {
    min-height: 90vh !important;
  }

  .vw-lg-100 {
    width: 100vw !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .min-vw-lg-100 {
    min-width: 100vw !important;
  }

  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
}

@media (min-width: 1200px) {
  .vw-xl-10 {
    width: 10vw !important;
  }

  .vh-xl-10 {
    height: 10vh !important;
  }

  .min-vw-xl-10 {
    min-width: 10vw !important;
  }

  .min-vh-xl-10 {
    min-height: 10vh !important;
  }

  .vw-xl-20 {
    width: 20vw !important;
  }

  .vh-xl-20 {
    height: 20vh !important;
  }

  .min-vw-xl-20 {
    min-width: 20vw !important;
  }

  .min-vh-xl-20 {
    min-height: 20vh !important;
  }

  .vw-xl-25 {
    width: 25vw !important;
  }

  .vh-xl-25 {
    height: 25vh !important;
  }

  .min-vw-xl-25 {
    min-width: 25vw !important;
  }

  .min-vh-xl-25 {
    min-height: 25vh !important;
  }

  .vw-xl-30 {
    width: 30vw !important;
  }

  .vh-xl-30 {
    height: 30vh !important;
  }

  .min-vw-xl-30 {
    min-width: 30vw !important;
  }

  .min-vh-xl-30 {
    min-height: 30vh !important;
  }

  .vw-xl-40 {
    width: 40vw !important;
  }

  .vh-xl-40 {
    height: 40vh !important;
  }

  .min-vw-xl-40 {
    min-width: 40vw !important;
  }

  .min-vh-xl-40 {
    min-height: 40vh !important;
  }

  .vw-xl-50 {
    width: 50vw !important;
  }

  .vh-xl-50 {
    height: 50vh !important;
  }

  .min-vw-xl-50 {
    min-width: 50vw !important;
  }

  .min-vh-xl-50 {
    min-height: 50vh !important;
  }

  .vw-xl-60 {
    width: 60vw !important;
  }

  .vh-xl-60 {
    height: 60vh !important;
  }

  .min-vw-xl-60 {
    min-width: 60vw !important;
  }

  .min-vh-xl-60 {
    min-height: 60vh !important;
  }

  .vw-xl-70 {
    width: 70vw !important;
  }

  .vh-xl-70 {
    height: 70vh !important;
  }

  .min-vw-xl-70 {
    min-width: 70vw !important;
  }

  .min-vh-xl-70 {
    min-height: 70vh !important;
  }

  .vw-xl-75 {
    width: 75vw !important;
  }

  .vh-xl-75 {
    height: 75vh !important;
  }

  .min-vw-xl-75 {
    min-width: 75vw !important;
  }

  .min-vh-xl-75 {
    min-height: 75vh !important;
  }

  .vw-xl-80 {
    width: 80vw !important;
  }

  .vh-xl-80 {
    height: 80vh !important;
  }

  .min-vw-xl-80 {
    min-width: 80vw !important;
  }

  .min-vh-xl-80 {
    min-height: 80vh !important;
  }

  .vw-xl-90 {
    width: 90vw !important;
  }

  .vh-xl-90 {
    height: 90vh !important;
  }

  .min-vw-xl-90 {
    min-width: 90vw !important;
  }

  .min-vh-xl-90 {
    min-height: 90vh !important;
  }

  .vw-xl-100 {
    width: 100vw !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .min-vw-xl-100 {
    min-width: 100vw !important;
  }

  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
}

@media (min-width: 1500px) {
  .vw-xxl-10 {
    width: 10vw !important;
  }

  .vh-xxl-10 {
    height: 10vh !important;
  }

  .min-vw-xxl-10 {
    min-width: 10vw !important;
  }

  .min-vh-xxl-10 {
    min-height: 10vh !important;
  }

  .vw-xxl-20 {
    width: 20vw !important;
  }

  .vh-xxl-20 {
    height: 20vh !important;
  }

  .min-vw-xxl-20 {
    min-width: 20vw !important;
  }

  .min-vh-xxl-20 {
    min-height: 20vh !important;
  }

  .vw-xxl-25 {
    width: 25vw !important;
  }

  .vh-xxl-25 {
    height: 25vh !important;
  }

  .min-vw-xxl-25 {
    min-width: 25vw !important;
  }

  .min-vh-xxl-25 {
    min-height: 25vh !important;
  }

  .vw-xxl-30 {
    width: 30vw !important;
  }

  .vh-xxl-30 {
    height: 30vh !important;
  }

  .min-vw-xxl-30 {
    min-width: 30vw !important;
  }

  .min-vh-xxl-30 {
    min-height: 30vh !important;
  }

  .vw-xxl-40 {
    width: 40vw !important;
  }

  .vh-xxl-40 {
    height: 40vh !important;
  }

  .min-vw-xxl-40 {
    min-width: 40vw !important;
  }

  .min-vh-xxl-40 {
    min-height: 40vh !important;
  }

  .vw-xxl-50 {
    width: 50vw !important;
  }

  .vh-xxl-50 {
    height: 50vh !important;
  }

  .min-vw-xxl-50 {
    min-width: 50vw !important;
  }

  .min-vh-xxl-50 {
    min-height: 50vh !important;
  }

  .vw-xxl-60 {
    width: 60vw !important;
  }

  .vh-xxl-60 {
    height: 60vh !important;
  }

  .min-vw-xxl-60 {
    min-width: 60vw !important;
  }

  .min-vh-xxl-60 {
    min-height: 60vh !important;
  }

  .vw-xxl-70 {
    width: 70vw !important;
  }

  .vh-xxl-70 {
    height: 70vh !important;
  }

  .min-vw-xxl-70 {
    min-width: 70vw !important;
  }

  .min-vh-xxl-70 {
    min-height: 70vh !important;
  }

  .vw-xxl-75 {
    width: 75vw !important;
  }

  .vh-xxl-75 {
    height: 75vh !important;
  }

  .min-vw-xxl-75 {
    min-width: 75vw !important;
  }

  .min-vh-xxl-75 {
    min-height: 75vh !important;
  }

  .vw-xxl-80 {
    width: 80vw !important;
  }

  .vh-xxl-80 {
    height: 80vh !important;
  }

  .min-vw-xxl-80 {
    min-width: 80vw !important;
  }

  .min-vh-xxl-80 {
    min-height: 80vh !important;
  }

  .vw-xxl-90 {
    width: 90vw !important;
  }

  .vh-xxl-90 {
    height: 90vh !important;
  }

  .min-vw-xxl-90 {
    min-width: 90vw !important;
  }

  .min-vh-xxl-90 {
    min-height: 90vh !important;
  }

  .vw-xxl-100 {
    width: 100vw !important;
  }

  .vh-xxl-100 {
    height: 100vh !important;
  }

  .min-vw-xxl-100 {
    min-width: 100vw !important;
  }

  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }
}

.svg-divider {
  position: absolute;
  left: 0;
  right: 0;
}

.svg-divider-top {
  top: -1px;
  transform: scaleX(-1) scaleY(-1);
}

.svg-divider-bottom {
  bottom: -1px;
}

.text-pastel-primary {
  color: #8bbffe !important;
}

.link-pastel-primary {
  color: #8bbffe;
}

.link-pastel-primary:focus,
.link-pastel-primary:hover {
  color: #d7e9ff;
}

.text-pastel-secondary {
  color: #a9b3c5 !important;
}

.link-pastel-secondary {
  color: #a9b3c5;
}

.link-pastel-secondary:focus,
.link-pastel-secondary:hover {
  color: #7b8ba6;
}

.text-pastel-success {
  color: #95ddc6 !important;
}

.link-pastel-success {
  color: #95ddc6;
}

.link-pastel-success:focus,
.link-pastel-success:hover {
  color: #cff0e5;
}

.text-pastel-info {
  color: #87daff !important;
}

.link-pastel-info {
  color: #87daff;
}

.link-pastel-info:focus,
.link-pastel-info:hover {
  color: #d4f2ff;
}

.text-pastel-warning {
  color: #fee28d !important;
}

.link-pastel-warning {
  color: #fee28d;
}

.link-pastel-warning:focus,
.link-pastel-warning:hover {
  color: #fff5d9;
}

.text-pastel-danger {
  color: #dd9d9d !important;
}

.link-pastel-danger {
  color: #dd9d9d;
}

.link-pastel-danger:focus,
.link-pastel-danger:hover {
  color: #c96464;
}

.text-pastel-light {
  color: #fafcfd !important;
}

.link-pastel-light {
  color: #fafcfd;
}

.link-pastel-light:focus,
.link-pastel-light:hover {
  color: #fff;
}

.text-pastel-dark {
  color: #8a92a4 !important;
}

.link-pastel-dark {
  color: #8a92a4;
}

.link-pastel-dark:focus,
.link-pastel-dark:hover {
  color: #636b7f;
}

.text-pastel-purple {
  color: #afb6f3 !important;
}

.link-pastel-purple {
  color: #afb6f3;
}

.link-pastel-purple:focus,
.link-pastel-purple:hover {
  color: #f2f3fd;
}

.text-pastel-sky {
  color: #f4f9ff !important;
}

.link-pastel-sky {
  color: #f4f9ff;
}

.link-pastel-sky:focus,
.link-pastel-sky:hover {
  color: #fff;
}

.text-pastel-pistachio {
  color: #edfaf9 !important;
}

.link-pastel-pistachio {
  color: #edfaf9;
}

.link-pastel-pistachio:focus,
.link-pastel-pistachio:hover {
  color: #fff;
}

.text-pastel-darkblue {
  color: #8b91b6 !important;
}

.link-pastel-darkblue {
  color: #8b91b6;
}

.link-pastel-darkblue:focus,
.link-pastel-darkblue:hover {
  color: #5e6696;
}

.font-georgia {
  font-family: Georgia, Merriweather, Arial;
}

.font-merriweather {
  font-family: Merriweather, Georgia;
}

.text-uppercase {
  letter-spacing: 0.8px;
}

.text-bg-clip {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: #fff;
}

.text-uppercase-bold-sm {
  text-transform: uppercase !important;
  font-weight: 500 !important;
  letter-spacing: 2px !important;
  font-size: 0.85rem !important;
}

.text-uppercase-xs {
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  font-size: 0.7rem !important;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.rotate--90-deg {
  transform: rotate(-90deg);
  transform-origin: center left;
}

.img-skew {
  perspective: 1550px;
  transform-style: preserve-3d;
}

.img-skew-right {
  perspective-origin: center;
}

.img-skew-right .img-skew-pic {
  transform: rotateY(15deg) rotateX(5deg) rotateZ(-5deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.img-skew-left {
  perspective-origin: left center;
}

.img-skew-left .img-skew-pic {
  transform: rotateY(-22deg) rotateX(6deg) rotateZ(5deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-skew-left {
  transform: perspective(3000px) rotateY(-15deg) rotateX(4deg) rotate(2deg);
}

.card-skew-right {
  transform: perspective(3000px) rotateY(14deg) rotate(-2deg);
}

.translate-y-1 {
  transform: translateY(1px);
}

.translate-y--1 {
  transform: translateY(-1px);
}

.translate-y-2 {
  transform: translateY(2px);
}

.translate-y--2 {
  transform: translateY(-2px);
}

.translate-y-3 {
  transform: translateY(3px);
}

.translate-y--3 {
  transform: translateY(-3px);
}

.translate-y-4 {
  transform: translateY(4px);
}

.translate-y--4 {
  transform: translateY(-4px);
}

.translate-y-5 {
  transform: translateY(5px);
}

.translate-y--5 {
  transform: translateY(-5px);
}

.translate-y-6 {
  transform: translateY(6px);
}

.translate-y--6 {
  transform: translateY(-6px);
}

.translate-y-7 {
  transform: translateY(7px);
}

.translate-y--7 {
  transform: translateY(-7px);
}

.translate-y-8 {
  transform: translateY(8px);
}

.translate-y--8 {
  transform: translateY(-8px);
}

.translate-y-9 {
  transform: translateY(9px);
}

.translate-y--9 {
  transform: translateY(-9px);
}

.translate-y-10 {
  transform: translateY(10px);
}

.translate-y--10 {
  transform: translateY(-10px);
}

.collapse-icon {
  transition: transform 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapse-icon {
    transition: none;
  }
}

[aria-expanded="true"] .collapse-icon {
  transform: rotate(180deg);
}

.avatar {
  display: inline-block;
  width: 2.85rem;
  height: 2.85rem;
  font-size: 1rem;
}

.avatar+.avatar {
  margin-left: 0.2rem;
}

.avatar-group {
  display: inline-flex;
}

.avatar-group .avatar+.avatar {
  margin-left: -1.05rem;
}

.avatar-group .avatar+.avatar .avatar-image,
.avatar-group .avatar+.avatar .avatar-letters {
  border: 2px solid #fff;
}

.avatar-group .avatar-xl+.avatar-xl {
  margin-left: -1.45rem;
}

.avatar-group .avatar-lg+.avatar-lg {
  margin-left: -1.2rem;
}

.avatar-group .avatar-sm+.avatar-sm {
  margin-left: -0.7rem;
}

.avatar-group .avatar-xs+.avatar-xs {
  margin-left: -0.6rem;
}

.avatar-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-letters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.avatar-xl {
  width: 5.5rem;
  height: 5.5rem;
  font-size: 1.85rem;
}

.avatar-lg {
  width: 4.15rem;
  height: 4.15rem;
  font-size: 1.35rem;
}

.avatar-sm {
  width: 1.8rem;
  height: 1.8rem;
  font-size: 0.85rem;
}

.avatar-xs {
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.6rem;
}

.badge-lg {
  padding: 0.7rem 1.1rem;
  font-size: 0.9rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.65em;
  content: "\f054";
  align-self: center;
}

body.rtl .breadcrumb-item:last-child:after {
  content: "";
}

body.rtl .breadcrumb-item:before {
  content: "";
}

body.rtl .breadcrumb-item:after {
  padding-right: 0.5rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.65em;
  content: "\f053";
  align-self: center;
}

.btn-pastel-primary {
  color: #2083fe;
  background-color: #e4f0ff;
  border-color: #e4f0ff;
}

.btn-pastel-primary:hover {
  color: #2083fe;
  background-color: #c1dcff;
  border-color: #c1dcff;
}

.btn-check:focus+.btn-pastel-primary,
.btn-pastel-primary:focus {
  color: #2083fe;
  background-color: #c1dcff;
  border-color: #c1dcff;
  box-shadow: 0 0 0 0.25rem rgba(199, 224, 255, 0.5);
}

.btn-check:active+.btn-pastel-primary,
.btn-check:checked+.btn-pastel-primary,
.btn-pastel-primary.active,
.btn-pastel-primary:active,
.show>.btn-pastel-primary.dropdown-toggle {
  color: #2083fe;
  background-color: #9dc8ff;
  border-color: #9dc8ff;
}

.btn-check:active+.btn-pastel-primary:focus,
.btn-check:checked+.btn-pastel-primary:focus,
.btn-pastel-primary.active:focus,
.btn-pastel-primary:active:focus,
.show>.btn-pastel-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(199, 224, 255, 0.5);
}

.btn-pastel-primary.disabled,
.btn-pastel-primary:disabled {
  color: #000;
  background-color: #e4f0ff;
  border-color: #e4f0ff;
}

.btn-pastel-secondary {
  color: #5a6c90;
  background-color: #ebedf2;
  border-color: #ebedf2;
}

.btn-pastel-secondary:hover {
  color: #5a6c90;
  background-color: #d1d6e0;
  border-color: #d1d6e0;
}

.btn-check:focus+.btn-pastel-secondary,
.btn-pastel-secondary:focus {
  color: #5a6c90;
  background-color: #d1d6e0;
  border-color: #d1d6e0;
  box-shadow: 0 0 0 0.25rem rgba(213, 218, 227, 0.5);
}

.btn-check:active+.btn-pastel-secondary,
.btn-check:checked+.btn-pastel-secondary,
.btn-pastel-secondary.active,
.btn-pastel-secondary:active,
.show>.btn-pastel-secondary.dropdown-toggle {
  color: #5a6c90;
  background-color: #b6bece;
  border-color: #b6bece;
}

.btn-check:active+.btn-pastel-secondary:focus,
.btn-check:checked+.btn-pastel-secondary:focus,
.btn-pastel-secondary.active:focus,
.btn-pastel-secondary:active:focus,
.show>.btn-pastel-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(213, 218, 227, 0.5);
}

.btn-pastel-secondary.disabled,
.btn-pastel-secondary:disabled {
  color: #000;
  background-color: #ebedf2;
  border-color: #ebedf2;
}

.btn-pastel-success {
  color: #34bd91;
  background-color: #e7f7f2;
  border-color: #e7f7f2;
}

.btn-pastel-success:hover {
  color: #34bd91;
  background-color: #c6ede0;
  border-color: #c6ede0;
}

.btn-check:focus+.btn-pastel-success,
.btn-pastel-success:focus {
  color: #34bd91;
  background-color: #c6ede0;
  border-color: #c6ede0;
  box-shadow: 0 0 0 0.25rem rgba(204, 238, 227, 0.5);
}

.btn-check:active+.btn-pastel-success,
.btn-check:checked+.btn-pastel-success,
.btn-pastel-success.active,
.btn-pastel-success:active,
.show>.btn-pastel-success.dropdown-toggle {
  color: #34bd91;
  background-color: #a6e2cf;
  border-color: #a6e2cf;
}

.btn-check:active+.btn-pastel-success:focus,
.btn-check:checked+.btn-pastel-success:focus,
.btn-pastel-success.active:focus,
.btn-pastel-success:active:focus,
.show>.btn-pastel-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 238, 227, 0.5);
}

.btn-pastel-success.disabled,
.btn-pastel-success:disabled {
  color: #000;
  background-color: #e7f7f2;
  border-color: #e7f7f2;
}

.btn-pastel-info {
  color: #18b7ff;
  background-color: #e3f6ff;
  border-color: #e3f6ff;
}

.btn-pastel-info:hover {
  color: #18b7ff;
  background-color: #beebff;
  border-color: #beebff;
}

.btn-check:focus+.btn-pastel-info,
.btn-pastel-info:focus {
  color: #18b7ff;
  background-color: #beebff;
  border-color: #beebff;
  box-shadow: 0 0 0 0.25rem rgba(197, 237, 255, 0.5);
}

.btn-check:active+.btn-pastel-info,
.btn-check:checked+.btn-pastel-info,
.btn-pastel-info.active,
.btn-pastel-info:active,
.show>.btn-pastel-info.dropdown-toggle {
  color: #18b7ff;
  background-color: #99dfff;
  border-color: #99dfff;
}

.btn-check:active+.btn-pastel-info:focus,
.btn-check:checked+.btn-pastel-info:focus,
.btn-pastel-info.active:focus,
.btn-pastel-info:active:focus,
.show>.btn-pastel-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 237, 255, 0.5);
}

.btn-pastel-info.disabled,
.btn-pastel-info:disabled {
  color: #000;
  background-color: #e3f6ff;
  border-color: #e3f6ff;
}

.btn-pastel-warning {
  color: #fdc724;
  background-color: #fff8e5;
  border-color: #fff8e5;
}

.btn-pastel-warning:hover {
  color: #fdc724;
  background-color: #feefc2;
  border-color: #feefc2;
}

.btn-check:focus+.btn-pastel-warning,
.btn-pastel-warning:focus {
  color: #fdc724;
  background-color: #feefc2;
  border-color: #feefc2;
  box-shadow: 0 0 0 0.25rem rgba(255, 241, 200, 0.5);
}

.btn-check:active+.btn-pastel-warning,
.btn-check:checked+.btn-pastel-warning,
.btn-pastel-warning.active,
.btn-pastel-warning:active,
.show>.btn-pastel-warning.dropdown-toggle {
  color: #fdc724;
  background-color: #fee69f;
  border-color: #fee69f;
}

.btn-check:active+.btn-pastel-warning:focus,
.btn-check:checked+.btn-pastel-warning:focus,
.btn-pastel-warning.active:focus,
.btn-pastel-warning:active:focus,
.show>.btn-pastel-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 241, 200, 0.5);
}

.btn-pastel-warning.disabled,
.btn-pastel-warning:disabled {
  color: #000;
  background-color: #fff8e5;
  border-color: #fff8e5;
}

.btn-pastel-danger {
  color: #bd4242;
  background-color: #f7e8e8;
  border-color: #f7e8e8;
}

.btn-pastel-danger:hover {
  color: #bd4242;
  background-color: #edcaca;
  border-color: #edcaca;
}

.btn-check:focus+.btn-pastel-danger,
.btn-pastel-danger:focus {
  color: #bd4242;
  background-color: #edcaca;
  border-color: #edcaca;
  box-shadow: 0 0 0 0.25rem rgba(238, 207, 207, 0.5);
}

.btn-check:active+.btn-pastel-danger,
.btn-check:checked+.btn-pastel-danger,
.btn-pastel-danger.active,
.btn-pastel-danger:active,
.show>.btn-pastel-danger.dropdown-toggle {
  color: #bd4242;
  background-color: #e2acac;
  border-color: #e2acac;
}

.btn-check:active+.btn-pastel-danger:focus,
.btn-check:checked+.btn-pastel-danger:focus,
.btn-pastel-danger.active:focus,
.btn-pastel-danger:active:focus,
.show>.btn-pastel-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(238, 207, 207, 0.5);
}

.btn-pastel-danger.disabled,
.btn-pastel-danger:disabled {
  color: #000;
  background-color: #f7e8e8;
  border-color: #f7e8e8;
}

.btn-pastel-light {
  color: #f6f9fc;
  background-color: #fefeff;
  border-color: #fefeff;
}

.btn-pastel-light:hover {
  color: #f6f9fc;
  background-color: #fcfdfe;
  border-color: #fcfdfe;
}

.btn-check:focus+.btn-pastel-light,
.btn-pastel-light:focus {
  color: #f6f9fc;
  background-color: #fcfdfe;
  border-color: #fcfdfe;
  box-shadow: 0 0 0 0.25rem rgba(253, 253, 255, 0.5);
}

.btn-check:active+.btn-pastel-light,
.btn-check:checked+.btn-pastel-light,
.btn-pastel-light.active,
.btn-pastel-light:active,
.show>.btn-pastel-light.dropdown-toggle {
  color: #f6f9fc;
  background-color: #fbfcfe;
  border-color: #fbfcfe;
}

.btn-check:active+.btn-pastel-light:focus,
.btn-check:checked+.btn-pastel-light:focus,
.btn-pastel-light.active:focus,
.btn-pastel-light:active:focus,
.show>.btn-pastel-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 253, 255, 0.5);
}

.btn-pastel-light.disabled,
.btn-pastel-light:disabled {
  color: #000;
  background-color: #fefeff;
  border-color: #fefeff;
}

.btn-pastel-dark {
  color: #1e2e50;
  background-color: #e4e6ea;
  border-color: #e4e6ea;
}

.btn-pastel-dark:hover {
  color: #1e2e50;
  background-color: #c0c4ce;
  border-color: #c0c4ce;
}

.btn-check:focus+.btn-pastel-dark,
.btn-pastel-dark:focus {
  color: #1e2e50;
  background-color: #c0c4ce;
  border-color: #c0c4ce;
  box-shadow: 0 0 0 0.25rem rgba(198, 202, 211, 0.5);
}

.btn-check:active+.btn-pastel-dark,
.btn-check:checked+.btn-pastel-dark,
.btn-pastel-dark.active,
.btn-pastel-dark:active,
.show>.btn-pastel-dark.dropdown-toggle {
  color: #1e2e50;
  background-color: #9ca3b2;
  border-color: #9ca3b2;
}

.btn-check:active+.btn-pastel-dark:focus,
.btn-check:checked+.btn-pastel-dark:focus,
.btn-pastel-dark.active:focus,
.btn-pastel-dark:active:focus,
.show>.btn-pastel-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(198, 202, 211, 0.5);
}

.btn-pastel-dark.disabled,
.btn-pastel-dark:disabled {
  color: #000;
  background-color: #e4e6ea;
  border-color: #e4e6ea;
}

.btn-pastel-purple {
  color: #6672e8;
  background-color: #edeefc;
  border-color: #edeefc;
}

.btn-pastel-purple:hover {
  color: #6672e8;
  background-color: #d4d8f9;
  border-color: #d4d8f9;
}

.btn-check:focus+.btn-pastel-purple,
.btn-pastel-purple:focus {
  color: #6672e8;
  background-color: #d4d8f9;
  border-color: #d4d8f9;
  box-shadow: 0 0 0 0.25rem rgba(217, 219, 249, 0.5);
}

.btn-check:active+.btn-pastel-purple,
.btn-check:checked+.btn-pastel-purple,
.btn-pastel-purple.active,
.btn-pastel-purple:active,
.show>.btn-pastel-purple.dropdown-toggle {
  color: #6672e8;
  background-color: #bcc1f5;
  border-color: #bcc1f5;
}

.btn-check:active+.btn-pastel-purple:focus,
.btn-check:checked+.btn-pastel-purple:focus,
.btn-pastel-purple.active:focus,
.btn-pastel-purple:active:focus,
.show>.btn-pastel-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 219, 249, 0.5);
}

.btn-pastel-purple.disabled,
.btn-pastel-purple:disabled {
  color: #000;
  background-color: #edeefc;
  border-color: #edeefc;
}

.btn-pastel-sky {
  color: #e9f4ff;
  background-color: #fcfeff;
  border-color: #fcfeff;
}

.btn-pastel-sky:hover {
  color: #e9f4ff;
  background-color: #f9fcff;
  border-color: #f9fcff;
}

.btn-check:focus+.btn-pastel-sky,
.btn-pastel-sky:focus {
  color: #e9f4ff;
  background-color: #f9fcff;
  border-color: #f9fcff;
  box-shadow: 0 0 0 0.25rem rgba(249, 253, 255, 0.5);
}

.btn-check:active+.btn-pastel-sky,
.btn-check:checked+.btn-pastel-sky,
.btn-pastel-sky.active,
.btn-pastel-sky:active,
.show>.btn-pastel-sky.dropdown-toggle {
  color: #e9f4ff;
  background-color: #f5faff;
  border-color: #f5faff;
}

.btn-check:active+.btn-pastel-sky:focus,
.btn-check:checked+.btn-pastel-sky:focus,
.btn-pastel-sky.active:focus,
.btn-pastel-sky:active:focus,
.show>.btn-pastel-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(249, 253, 255, 0.5);
}

.btn-pastel-sky.disabled,
.btn-pastel-sky:disabled {
  color: #000;
  background-color: #fcfeff;
  border-color: #fcfeff;
}

.btn-pastel-pistachio {
  color: #ddf5f4;
  background-color: #fbfefe;
  border-color: #fbfefe;
}

.btn-pastel-pistachio:hover {
  color: #ddf5f4;
  background-color: #f5fcfc;
  border-color: #f5fcfc;
}

.btn-check:focus+.btn-pastel-pistachio,
.btn-pastel-pistachio:focus {
  color: #ddf5f4;
  background-color: #f5fcfc;
  border-color: #f5fcfc;
  box-shadow: 0 0 0 0.25rem rgba(247, 253, 253, 0.5);
}

.btn-check:active+.btn-pastel-pistachio,
.btn-check:checked+.btn-pastel-pistachio,
.btn-pastel-pistachio.active,
.btn-pastel-pistachio:active,
.show>.btn-pastel-pistachio.dropdown-toggle {
  color: #ddf5f4;
  background-color: #f0fbfa;
  border-color: #f0fbfa;
}

.btn-check:active+.btn-pastel-pistachio:focus,
.btn-check:checked+.btn-pastel-pistachio:focus,
.btn-pastel-pistachio.active:focus,
.btn-pastel-pistachio:active:focus,
.show>.btn-pastel-pistachio.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 253, 253, 0.5);
}

.btn-pastel-pistachio.disabled,
.btn-pastel-pistachio:disabled {
  color: #000;
  background-color: #fbfefe;
  border-color: #fbfefe;
}

.btn-pastel-darkblue {
  color: #1f2c73;
  background-color: #e4e6ee;
  border-color: #e4e6ee;
}

.btn-pastel-darkblue:hover {
  color: #1f2c73;
  background-color: #c0c4d8;
  border-color: #c0c4d8;
}

.btn-check:focus+.btn-pastel-darkblue,
.btn-pastel-darkblue:focus {
  color: #1f2c73;
  background-color: #c0c4d8;
  border-color: #c0c4d8;
  box-shadow: 0 0 0 0.25rem rgba(198, 202, 220, 0.5);
}

.btn-check:active+.btn-pastel-darkblue,
.btn-check:checked+.btn-pastel-darkblue,
.btn-pastel-darkblue.active,
.btn-pastel-darkblue:active,
.show>.btn-pastel-darkblue.dropdown-toggle {
  color: #1f2c73;
  background-color: #9ca2c1;
  border-color: #9ca2c1;
}

.btn-check:active+.btn-pastel-darkblue:focus,
.btn-check:checked+.btn-pastel-darkblue:focus,
.btn-pastel-darkblue.active:focus,
.btn-pastel-darkblue:active:focus,
.show>.btn-pastel-darkblue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(198, 202, 220, 0.5);
}

.btn-pastel-darkblue.disabled,
.btn-pastel-darkblue:disabled {
  color: #000;
  background-color: #e4e6ee;
  border-color: #e4e6ee;
}

.btn-white {
  color: #1f2c73;
  background-color: #fff;
  border-color: #e9ecef;
}

.btn-white:hover {
  color: #1f2c73;
  background-color: #f6f9fc;
  border-color: #e9ecef;
}

.btn-check:focus+.btn-white,
.btn-white:focus {
  color: #1f2c73;
  background-color: #f6f9fc;
  border-color: #e9ecef;
  box-shadow: 0 0 0 0.25rem rgba(203, 207, 220, 0.5);
}

.btn-check:active+.btn-white,
.btn-check:checked+.btn-white,
.btn-white.active,
.btn-white:active,
.show>.btn-white.dropdown-toggle {
  color: #1f2c73;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-check:active+.btn-white:focus,
.btn-check:checked+.btn-white:focus,
.btn-white.active:focus,
.btn-white:active:focus,
.show>.btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(203, 207, 220, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
  color: #000;
  background-color: #fff;
  border-color: #e9ecef;
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 768px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card-header {
  border-bottom: 1px solid rgba(30, 46, 80, 0.09);
}

.card-footer-btn {
  display: flex;
  align-items: center;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.card-bottom-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1.75rem 1.5rem;
}

.video-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.25rem;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.275);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-submenu .dropdown-toggle:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  display: inline-block;
  font-size: 10px;
  position: absolute;
  right: 14px;
  top: 8px;
  color: #757886;
}

@media (max-width: 991.98px) {
  .dropdown-submenu .dropdown-menu {
    border-color: #fbfbfb;
  }

  .dropdown-submenu .dropdown-menu .dropdown-item {
    padding-left: 2rem;
  }
}

@media (min-width: 992px) {
  .dropdown-submenu .dropdown-menu {
    left: 98%;
    top: -2px;
  }
}

body.rtl .dropdown-submenu .dropdown-toggle:before {
  right: initial;
  left: 13px;
  transform: rotateY(180deg);
}

@media (min-width: 992px) {
  body.rtl .dropdown-submenu .dropdown-menu {
    left: initial;
    right: 98%;
  }
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  font-size: 10px;
  border: none;
  position: relative;
  top: 2px;
}

.dropdown-submenu .dropdown-toggle::after,
.navbar .dropdown-toggle::after {
  display: none;
}

.footer {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem;
}

.footer-header {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

.footer-link {
  font-size: 0.875rem;
  display: block;
}

.footer-text {
  font-size: 0.7rem;
}

.footer-light .footer-header {
  color: #1e2e50;
}

.footer-light .footer-link {
  color: #1f2c73;
}

.footer-light .footer-social-icon {
  background-color: #1e2e50;
  color: #f6f9fc;
}

.footer-light .footer-text {
  color: rgba(30, 46, 80, 0.8);
}

.footer-light .footer-brand {
  color: #1e2e50;
}

.footer-dark .footer-header {
  color: #18b7ff;
}

.footer-dark .footer-link {
  color: #e9f4ff;
}

.footer-dark .footer-social-icon {
  background-color: #e9f4ff;
  color: #1e2e50;
}

.footer-dark .footer-text {
  color: rgba(233, 244, 255, 0.6);
}

.footer-dark .footer-brand {
  color: #e9f4ff;
}

.form-switch .form-check-input {
  height: 1.5em;
  cursor: pointer;
  margin-top: 0;
}

.form-control-bottom {
  border: 0;
  border-radius: 0;
  padding: 0;
  border-bottom: 1px solid #dee2e6;
}

.list {
  padding-left: 0;
  list-style: none;
}

.list-item {
  margin-bottom: 0.6rem;
  position: relative;
}

.list-item.active:after {
  content: "";
  position: absolute;
  left: -1.75rem;
  height: 100%;
  width: 2px;
  background: #6672e8;
}

body.rtl .list {
  padding-right: 0;
}

body.rtl .list-item.active:after {
  left: initial;
  right: -1.75rem;
}

.navbar {
  z-index: 1034;
  transition: background-color 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .navbar {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    margin-top: 0;
    box-shadow: 0 1.5rem 3rem rgba(75, 78, 88, 0.175);
    transform: translateY(10px);
    transition: all 0.25s;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    transform: none !important;
    z-index: 1034;
  }
}

.navbar-ecommerce {
  position: relative;
  z-index: 1035;
}

.navbar-light.bg-transparent.navbar-toggled {
  background-color: #fff !important;
}

.navbar-dark.bg-transparent.navbar-toggled {
  background-color: #212529 !important;
}

.iconav {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1036;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .iconav {
    top: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .iconav.iconav-left {
    left: 0;
  }

  .iconav.iconav-right {
    right: 0;
  }
}

@media (max-width: 991.98px) {
  .iconav {
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.iconav .iconav-brand {
  display: block;
  padding: 1.75rem 1rem;
  text-align: center;
}

@media (min-width: 992px) {
  .iconav .iconav-slider {
    flex: 1 1 auto;
  }
}

@media (max-width: 991.98px) {
  .iconav .iconav-slider {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
}

.iconav .iconav-nav {
  flex-wrap: nowrap;
  height: 100%;
}

@media (min-width: 992px) {
  .iconav .iconav-nav {
    flex-direction: column;
  }
}

.iconav .nav-link {
  text-align: center;
  transition: background-color 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .iconav .nav-link {
    transition: none;
  }
}

.iconav .nav-link-label {
  font-size: 0.9rem;
  display: block;
  margin-top: 0.5rem;
}

.iconav-light {
  background-color: #fff;
  border: 1px solid #eee;
}

.iconav-light .iconav-brand {
  background-color: #f3f4f7;
}

@media (min-width: 992px) {
  .iconav-light .nav-item+.nav-item .nav-link {
    border-top: 1px solid #eee;
  }
}

.iconav-light .nav-link {
  color: #1f2c73;
}

.iconav-light .nav-link:focus,
.iconav-light .nav-link:hover {
  background-color: #f8f9fa;
}

.iconav-light .nav-link.active {
  background-color: #f4f4f7;
}

.iconav-dark {
  background-color: #30343e;
  border: none;
}

.iconav-dark .iconav-brand {
  background-color: #0f1013;
}

@media (min-width: 992px) {
  .iconav-dark .nav-item+.nav-item .nav-link {
    border-top: 1px solid #212225;
  }
}

.iconav-dark .nav-link {
  color: #e9ecef;
}

.iconav-dark .nav-link:focus,
.iconav-dark .nav-link:hover {
  background-color: #252830;
}

.iconav-dark .nav-link.active {
  background-color: #20232b;
  color: #fff;
}

.schedule-date {
  line-height: 38px;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
}

.schedule-date:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 110%;
  margin: auto;
  bottom: -20px;
  left: -5px;
  right: 0;
  background: #dee2e6;
}

@media (max-width: 575.98px) {
  .schedule-date:after {
    width: 100%;
  }
}

.schedule-event {
  padding-top: 1.75rem;
  width: 100%;
  display: flex;
}

.schedule-time {
  display: inline-block;
  width: 40%;
  position: relative;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.schedule-time:before {
  content: "";
  position: absolute;
  right: 0;
  top: -1.75rem;
  width: 1px;
  height: 230%;
  background: #dee2e6;
}

.schedule-time:after {
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid #6672e8;
  background: #f6f9fc;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 7px;
  right: -5px;
}

.schedule-title {
  width: 60%;
  display: inline-block;
  padding-left: 2rem;
}

body.rtl .schedule-time:before {
  right: initial;
  left: 0;
}

body.rtl .schedule-time:after {
  right: initial;
  left: -5px;
}

.statcard {
  border-radius: 0.25rem;
  padding: 1.5rem 1.5rem;
}

.statcard-value {
  display: flex;
  align-items: center;
}

.statcard-change {
  font-size: 0.8rem;
  margin-left: 0.75rem;
}

.statcard-change:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 3px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.statcard-change.statcard-change-positive {
  color: #34bd91;
}

.statcard-change.statcard-change-positive:after {
  border-top: 0;
  border-bottom: 5px solid;
}

.statcard-change.statcard-change-negative {
  color: #bd4242;
}

.statcard-change.statcard-change-negative:after {
  border-bottom: 0;
  border-top: 5px solid;
}

.statcard-label {
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #7184ad;
}

.statcard-primary {
  background: #2083fe;
  color: #fff;
}

.statcard-primary .statcard-change,
.statcard-primary .statcard-label {
  color: #fff !important;
}

.statcard-secondary {
  background: #5a6c90;
  color: #fff;
}

.statcard-secondary .statcard-change,
.statcard-secondary .statcard-label {
  color: #fff !important;
}

.statcard-success {
  background: #34bd91;
  color: #fff;
}

.statcard-success .statcard-change,
.statcard-success .statcard-label {
  color: #fff !important;
}

.statcard-info {
  background: #18b7ff;
  color: #fff;
}

.statcard-info .statcard-change,
.statcard-info .statcard-label {
  color: #fff !important;
}

.statcard-warning {
  background: #fdc724;
  color: #000;
}

.statcard-warning .statcard-change,
.statcard-warning .statcard-label {
  color: #000 !important;
}

.statcard-danger {
  background: #bd4242;
  color: #fff;
}

.statcard-danger .statcard-change,
.statcard-danger .statcard-label {
  color: #fff !important;
}

.statcard-light {
  background: #f6f9fc;
  color: #000;
}

.statcard-light .statcard-change,
.statcard-light .statcard-label {
  color: #000 !important;
}

.statcard-dark {
  background: #1e2e50;
  color: #fff;
}

.statcard-dark .statcard-change,
.statcard-dark .statcard-label {
  color: #fff !important;
}

.statcard-purple {
  background: #6672e8;
  color: #fff;
}

.statcard-purple .statcard-change,
.statcard-purple .statcard-label {
  color: #fff !important;
}

.statcard-sky {
  background: #e9f4ff;
  color: #000;
}

.statcard-sky .statcard-change,
.statcard-sky .statcard-label {
  color: #000 !important;
}

.statcard-pistachio {
  background: #ddf5f4;
  color: #000;
}

.statcard-pistachio .statcard-change,
.statcard-pistachio .statcard-label {
  color: #000 !important;
}

.statcard-darkblue {
  background: #1f2c73;
  color: #fff;
}

.statcard-darkblue .statcard-change,
.statcard-darkblue .statcard-label {
  color: #fff !important;
}

.switch-prices {
  position: relative;
  height: 70px;
}

@media (min-width: 768px) {
  .switch-prices {
    height: 85px;
  }
}

.switch-price {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .switch-price {
    transition: none;
  }
}

.switch-price.switch-price-active {
  opacity: 1;
  transform: translateY(0) !important;
}

.switch-price.switch-price-monthly {
  transform: translateY(25px);
}

.switch-price.switch-price-yearly {
  transform: translateY(-25px);
}

.timeline {
  position: relative;
}

.timeline::after {
  display: block;
  clear: both;
  content: "";
}

.timeline:before {
  content: "";
  position: absolute;
  background: #e4e9f1;
  width: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.timeline-post {
  position: relative;
  float: left;
  width: 50%;
}

@media (max-width: 767.98px) {
  .timeline-post {
    width: 100%;
  }
}

.timeline-post:before {
  content: "";
  position: absolute;
  width: 90px;
  height: 4px;
  background: #e4e9f1;
  right: 0;
  top: 61px;
}

@media (max-width: 767.98px) {
  .timeline-post:before {
    display: none;
  }
}

.timeline-post:nth-child(2n) {
  float: right;
}

.timeline-post:nth-child(2n):before {
  right: inherit;
  left: 0;
}

.timeline-post:nth-child(2n) .timeline-post-marker {
  right: inherit;
  left: -8px;
}

@media (min-width: 768px) {
  .timeline-post:nth-child(2n) .timeline-post-content {
    float: right;
  }
}

.timeline-post+.timeline-post {
  margin-top: 5rem;
}

.timeline-post-content {
  position: relative;
}

@media (min-width: 768px) {
  .timeline-post-content {
    width: 85%;
    float: left;
  }
}

.timeline-post-marker {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 25px;
  background: #e4e9f1;
  top: 55px;
  border: 3px solid #f8f9fb;
  right: -8px;
}

@media (max-width: 575.98px) {
  .timeline-post-marker {
    display: none;
  }
}

.container-docs {
  padding-top: 83px;
}

@media (min-width: 992px) {
  .docs-sidebar {
    height: calc(100vh - 85px);
    position: fixed;
    overflow: auto;
  }
}

.docs-sidebar-left {
  left: 0;
}

.docs-sidebar-right {
  right: 0;
}

.docs-iconav {
  position: relative;
  z-index: 100;
}

@media (min-width: 768px) {
  .docs-iconav {
    display: inline-block;
  }
}

code {
  font-size: 80%;
}

/*# sourceMappingURL=theme.min.css.map */





.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffd700 ;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
    
